import { FaBusinessTime } from "react-icons/fa";
import { FaSuitcaseMedical, FaUserDoctor } from "react-icons/fa6";
import { TbToolsOff } from "react-icons/tb";
import demoImage from "../../images/service-one/Screenshot (49).png";
import serviceOne from "../../images/icon-wisdom.webp";
import serviceTwo from "../../images/icon-implant.webp";
import serviceFour from "../../images/icon-sedation.webp";
import serviceThree from "../../images/icon-ortho.webp";
import ToothFilling from "../../images/service-one/Screenshot (44).png";
import RootCanals from "../../images/services/best-root-canal-treatment-clinic.webp";
import Braces from "../../images/services/Braces.webp";
import Aligners from "../../images/services/Invisible-Aligners.webp";
import bridges from "../../images/service-one/Screenshot (45).png";
import Dentures from "../../images/service-one/Screenshot (46).png";
import disease from "../../images/service-one/Screenshot (47).png";
import Extraction from "../../images/service-one/74622cdf-c4a2-420e-9a71-f5b1212fca5a.JPG";
import dentistry from "../../images/service-one/Screenshot (48).png";
import Child from "../../images/services/kids-dental.webp";
import XRay from "../../images/service-one/Screenshot (51).png";
import Sinus from "../../images/services/Sinüs-Lifting-1024x683.jpg";
import BoneGrafting from "../../images/service-one/width_800.webp";
import Zygomatic from "../../images/services/shutterstock_1710200197-min-1024x779.jpg.optimal.jpg";
import ImplantSupported from "../../images/services/Implant-Supported-Dentures.jpg";
import AdvancedImplantology from "../../images/services/What_is_a_dental_implantology.jpg";
import WisdomTooth from "../../images/services/wisdom-teeth-1.webp";
import AbscessDrainage from "../../images/services/tooth-abscess-toronto-dentist.jpg";
import CystRemoval from "../../images/services/Dental_Cyst.webp";
import TongueTie from "../../images/services/tongue-tie-release.jpg";
import FrenumRelease from "../../images/services/freenctomy.jpg";
import FacialTrauma from "../../images/service-one/width_658.webp";
import Temporomandibular from "../../images/service-one/width_605.webp";
import CorrectiveJaw from "../../images/services/jaw-surgery.jpg";
import OralCancer from "../../images/service-one/width_714.webp";
import PathologyTumour from "../../images/service-one/width_800 (2).webp";
import FacialNerve from "../../images/services/trigeminalneur_1280.jpg";
import ScarRevision from "../../images/services/Facial-scar-Revision-in-Dubai-Abu-Dhabi-Sharjah-1.webp";
import CosmeticSurgery from "../../images/services/Understanding-the-Different-Types-of-Plastic-Surgery-Procedures.jpg";
import Dimploplasty from "../../images/services/DIMPLE_230859.jpg";
import Lobuloplasty from "../../images/services/earlobe.png";
import Botox from "../../images/services/AdobeStock_123309889-495x400.webp";
import DermalFillers from "../../images/services/Dermal-Fillers-Lacurra-Med-Spa-q98sdb1vjnkha5l0b92ega5wlhn1cc5bkgex6nti26.jpg";
import ChemicalPeels from "../../images/services/Chemical-Peel.jpg";
import MicrodermaAbrasion from "../../images/services/the-skin-concerns-that-will-benefit-from-a-microdermabrasion.jpeg";
import ScarRemoval from "../../images/services/permanent-scar-removal-miami-ca.jpg";
import SkinRejuvenation from "../../images/services/portrait_of_beautiful_woman_with_problem_and_clean_skin.webp";
import SkinPolish from "../../images/services/benefits-of-body-polishing.jpg";
import HydraFacial from "../../images/services/delighted-nice-positive-woman-having-hydrafacial-royalty-free-image-1681751745.jpg";
import Medifacial from "../../images/services/Medifacial.jpg";
import Microneedling from "../../images/services/2513085.png";
import wartRemoval from "../../images/services/Mole-Removal-shutterstock_1357061072-scaled-1-1536x1024.jpg";
import FacPRP from "../../images/services/Facial-PRP-08.webp";
import HairPRP from "../../images/services/PRP-Hair-Treatment-In-Roorkee.jpg";
import LipNeutralisation from "../../images/services/dark-lip-treatment-before-and-after.jpg";
import LipColour from "../../images/service-one/images.jfif";
import Microblading from "../../images/services/Microblading_Eyebrow_Shapes_The_Fundamental.webp";
import serviceMainOne from "../../images/service-one/dental-implant-treatment-in-kochi.jpg";
import serviceMainTwo from "../../images/service-one/bigstock-Teeth-and-dental-implant-isola-202798507.jpg";
import serviceMainThree from "../../images/service-one/All-Blog-Cover-4-1080x675.png";
import serviceMainFour from "../../images/service-one/Why-Choose-a-Certified-Cosmetic-Surgeon-For-Cosmetic-Surgery-Procedures-IAAPS-011.jpg";
import seserviceMainFive from "../../images/service-one/Screenshot 2024-07-04 at 1.31.48 AM.png";
import DentalVeneers from "../../images/service-one/Screenshot (50).png";
import ToothJewellery from "../../images/services/images (2).jfif";
import DentalImplants from "../../images/services/388058c6-6104-449e-8653-0129952eae67.png";
import ChronicDental from "../../images/services/head-jaw-pain-tooth-against-blue-backdrop-mouth-problem-tooth-pain-jaw-tooth-d-illustration-150656204 (1).webp";
import WrinkleTreatment from "../../images/services/Wrinkles-Treatment-1024x652.jpg";
import BrowLift from "../../images/services/DENVER-EYELID-LIFTS-AND-BROW-LIFTS-1024x683-1.jpeg";
import Rhinoplasty from "../../images/services/rhinoplasty-nose-surgery-rhinoplasty-surgeon-bhopal.jpg";
import ChronicFacial from "../../images/service-one/facial-pain-causes.png";
import Blepharoplasty from "../../images/service-one/width_800 (1).webp";
import MinorOral from "../../images/service-one/Screenshot 2024-07-04 at 1.26.27 AM.png";

export const servicesDoc = [
  {
    logo: <FaBusinessTime />,
    title: "Dental treatment",
    img: serviceOne,
    mainImg: serviceMainOne,
    mainDes:
      "We operate as a specialist-based clinic where all procedures are performed by highly skilled professionals with extensive experience",
    des: [
      "Dental procedures can help you with pain, infection, an injury or other problems with your teeth or gums. Dental procedures include: a filling or crown for your tooth, treatment for gum disease, straightening crowded teeth, dental surgery or having dentures made.",
      "Specialist dentist at the clinic include Orthodontist (braces & aligners), Endodontists (root canals), Prosthodontist (crown, bridge & dentures), Paediatric dentists (dental services for children), Periodontists (gum disease).",
    ],

    subTitle: [
      {
        name: "Tooth Filling & Root Canals",
        img: ToothFilling,
        mainDes: [
          "Tooth fillings are a type of treatment used to replace tooth structure that has been lost due to decay or trauma.",
        ],
        head: {
          heading: "What is tooth filling?",
          des: [
            "Tooth fillings are a type of treatment used to replace tooth structure that has been lost due to decay or trauma.",
            "In a Tooth filling the decayed and damaged portion of the tooth is removed and a dental material is placed to prevent further damage and restore function and appearance.The replacement material may be made of porcelain, glass ionomer, composite resin, gold, or silver amalgam.",
          ],
        },
        reason: {
          heading: "Reasons you might need a Tooth Filling:",
          des: [
            "In the event of a cavity",
            "Fractured or cracked tooth",
            "Chipped tooth",
            "In the case of tiny holes in teeth",
            "Fillings for discolored teeth",
            "In cases of gradual tooth structure loss",
          ],
        },
        materials: [
          {
            heading: "Metal fillings:",
            des: "Materials for dental fillings include metals like gold and silver. These metal fillings are the strongest and endure for at least 20 years. These are age-old fillings.",
          },
          {
            heading: "Amalgam fillings:",
            des: "Amalgam fillings do not match the color of natural teeth. It lasts at least 10 to 15 years. For large cavities and ones that develop in the back teeth, where a lot of chewing effort is required, it is the ideal option.",
          },
          {
            heading: "Glass ionomer cement fillings:",
            des: "Glass Ionomer cement is a tooth-colored dental filling material. It has a unique quality known as the “anti-cariogenic” property; this means that it stops caries from developing as a result of fluoride release.",
          },
          {
            heading: "Composite fillings:",
            des: "Composite is a tooth-colored Tooth filling material. After the tooth is prepared, the filling is bonded in the area and light cured. The dentist selects a shade to match your teeth therefore it is used where a natural appearance is desired.",
          },
          {
            heading: "Porcelain fillings:",
            des: "If you have a major cosmetic concern, porcelain fillings are the best option. These can be utilized if the decay is significant enough and covers the majority of the tooth. This durable filling also resists stains.",
          },
        ],
        procedure: [
          "We may perform one or two X-rays to determine the extent of tooth decay",
          "First, we will numb the tooth with a local anesthetic if necessary.",
          "Next, the decayed area is removed.",
          "A probe is inserted into the area to determine whether all of the decay has been thoroughly removed or not.",
          "Once the decay is removed, we will prepare space for filling by cleaning the cavity of bacteria and debris.",
          "After the filling is completed, the cleaning and polishing are done.",
          "In cases of tooth-colored fillings, the filling material is applied in layers. A special light that cures or hardens each layer is applied. When the filling is done, we will shape the filling material to the desired result",
        ],
      },
      // {
      //   name: "Root Canals Therapy",
      //   img: RootCanals,
      //   head: {
      //     heading: "What is Root Canal Treatment?",
      //     des: [
      //       "A root canal is a dental procedure used to treat an infected or inflamed tooth. It involves removing the damaged or infected pulp from the tooth, cleaning and disinfecting the inside of the tooth, and filling it with a material called gutta-percha. The tooth is then sealed with a filling or crown to prevent further infection. The procedure is typically done to save a damaged or infected tooth from extraction.",
      //       "With the first incidence of pain root canal treatment will give a better result to retain the teeth for a longer period of time and prevent the infection from spreading further.",
      //     ],
      //   },
      //   need: {
      //     heading: "Who needs Root canal treatment?",
      //     des: "Root canal treatment is done to relieve the pain.Toothache or pain occurs when there is a tooth infection brought on by decay, trauma, and Gum diseases This ranges from mild to severe. As you ignore it, it gets worse.",
      //   },
      //   procedure: [
      //     "To determine the extent of inflammation and tooth decay, we may perform one or two X-rays.",
      //     "The Endodontist anaesthetizes the tooth of interest and the surrounding area.",
      //     "The root canals and pulp chamber are cleaned using instruments. Cleaning and disinfecting the canals involves using antibacterial and antiseptic solutions. This gets rid of the bacteria and treats the canal infection.",
      //     "Next, the pulp canals are shaped using instruments so that they will be ready to receive the filling material.",
      //     "The pulp canals are filled using a rubber-like material called gutta-percha which is compressed against the canal walls, so it fits snuggly and avoids the entry of microorganisms.",
      //     "The treated tooth is enclosed with a crown, which provides additional strength and stability. The permanent crown is made to look exactly like the natural teeth.",
      //   ],
      //   post: {
      //     heading: "Post treatment protocol:",
      //     des: [
      //       "After the procedure, you will experience some pain and discomfort. So your dentist will recommend medication.",
      //       "Avoid smoking, alcohol, hot drinks, drinking with straws. These promote inflammation.",
      //       "Brush and floss normally, it’s important to keep the area clean.",
      //       "If you experience any complications, consult your dentist.",
      //     ],
      //   },
      // },
      {
        name: "Braces & Aligners",
        img: Braces,
        mainDes: [
          "Braces use brackets and wires to gradually align teeth, while aligners are clear, removable trays that gently shift teeth into place without metal components.",
        ],
        head: {
          heading: "What are Braces?",
          des: [
            "Braces are dental appliances that aid in the correction of dental issues such as crowding, crooked teeth, or teeth that are out of alignment. It can also be used to improve your smile. Many people get braces in their teens, but adults can get them too. Braces gradually straighten and align your teeth so that you have a normal bite as you wear them.",
            "Your dentist or orthodontist will ask you questions about your health, perform a clinical exam, take a digital scan of your teeth, photograph your face and teeth, order X-rays of your mouth and head, and will then devise a treatment plan for you. Based on the treatment plan you can opt for metal, ceramic or lingual braces.",
          ],
        },
        type: [
          {
            heading: "Traditional Braces",
            des: [
              "Traditional Dental braces are a dental model that uses metal. Everyone is familiar with these types of braces. Traditional Dental braces use metal brackets and wires to gradually realign teeth over a long period of time.",
            ],
            sub: [
              {
                heading: "PROS:",
                des: [
                  "They are an effective treatment for tough cases.",
                  "They are affordable and durable.",
                  "Traditional braces have long been regarded as the quickest way to straighten teeth.",
                ],
              },
              {
                heading: "CONS:",
                des: [
                  "Decalcification can occur in your teeth if you do not floss or brush properly while wearing metal braces.",
                  "Traditional braces are unsightly when smiling and might make some people feel insecure.",
                  "Some patients say wearing traditional braces causes them discomfort.",
                  "A patient has to avoid foods that are easily tucked into the brackets.",
                ],
              },
            ],
          },
          {
            heading: "Ceramic Braces",
            des: [
              "Like traditional braces, they rest on the front of the teeth, but because they are constructed of tooth-colored ceramic material, they blend in a little better. They function just as quickly and effectively as metal braces.",
            ],
            sub: [
              {
                heading: "PROS:",
                des: [
                  "Braces made of ceramic are far less obvious than metal ones.",
                  "Rapid treatment of any misalignment issues is possible with ceramic braces.",
                ],
              },
              {
                heading: "CONS:",
                des: [
                  "Ceramic braces are susceptible to staining if they are not properly maintained.",
                  "Patients who wear ceramic braces may complain of discomfort.",
                ],
              },
            ],
          },
          {
            heading: "Lingual Braces",
            des: [
              "Lingual braces are attached to the back of the teeth rather than the front. Since the tongue regularly comes into touch with them, they are more uncomfortable than conventional braces while being nearly undetectable.",
              "If you're considering having lingual braces, you should first discuss your specific situation with your orthodontist to determine whether they would be appropriate.",
            ],
            sub: [
              {
                heading: "PROS:",
                des: [
                  "Lingual braces are virtually undetectable when smiling.",
                  "Speed: Lingual braces can be quick if they're the correct choice for your situation.",
                ],
              },
              {
                heading: "CONS:",
                des: [
                  "Lingual braces are more costly than ceramic and conventional braces.",
                  "Due to the positioning of lingual braces, some patients claim to experience discomfort.",
                ],
              },
            ],
          },
        ],
        procedure: [
          {
            heading: "Preparation",
            des: [
              "The orthodontist installs rubber bands or spacers between teeth that are too closely spaced apart for a week before braces are applied.",
              "This will make room for the bands to fit comfortably around the rear teeth.",
            ],
          },
          {
            heading: "Putting the brackets in place",
            des: [
              "Tiny metal or ceramic components are called brackets to keep the wires in place on the teeth.",
              "The centre of each tooth is covered in glue or bonding cement to affix the bracket.",
            ],
          },
          {
            heading: "Placement of bands",
            subDes:
              "Metal bands are placed on the rear molars to secure the brackets in place.",
          },
          {
            heading: "Addition of Arch Wire",
            des: [
              "The orthodontist attaches the archwire to the brackets once the brackets and bands are in place.",
              "To do this, a little rubber band is wrapped around each bracket to secure the wire in place.",
            ],
          },
        ],
      },
      // {
      //   name: "Invisalign Braces (invisible braces)",
      //   img: Aligners,
      //   head: {
      //     heading: "Invisible Tooth Aligners",
      //     des: [
      //       "Invisible Tooth Aligners / Clear Aligners / transparent aligners are present advanced digitalized appliance which actively regulates the tooth movement and finishes the treatment in short time. Invisalign Aligners cost/ teeth aligner price depends mainly on the brand of the aligners used & no of trays taken for desired results of confident smile.",
      //       "Invisible Aligners are a type of clear orthodontic appliance that corrects your smile, without the need for messy wires. Aligners are custom-made for each patient starting off with a digital scan of your teeth and transferring the scans onto the digital memory trays.",
      //       "These are better than braces as invisible braces are almost undetectable and can be taken off, which is useful when eating or snacking. You won't get stuck with sticky food anymore, so relax! Simply take out your invisible braces, chew food, brush and floss your teeth, and then put them back on.",
      //       "Every couple of weeks, you'll get new clear braces. Together, each tray helps to gradually improve your smile.",
      //     ],
      //   },
      //   procedure: [
      //     {
      //       heading: "step 1:",
      //       des: [
      //         "A patient will first consult with an orthodontist.",
      //         "A treatment plan will be decided upon during the initial appointment, and bite impressions will also be taken.",
      //       ],
      //     },
      //     {
      //       heading: "Step 2:",
      //       des: [
      //         "Your bite impressions will be used to create personalized aligners using 3D imaging technology.",
      //         "Twelve to forty-eight aligners will be created for you to straighten your teeth, depending on your specific needs.",
      //       ],
      //     },
      //     {
      //       heading: "Step 3:",
      //       des: [
      //         "Your Invisible aligners will be worn after your initial session. Except when you eat, drink, or brush your teeth, invisible aligners are worn all the time.",
      //         "Two weeks are spent wearing each tray. Your orthodontist or dentists will likely check in with you every six weeks to make sure your treatment is progressing as planned.",
      //       ],
      //     },
      //     {
      //       heading: "Step 4:",
      //       des: [
      //         "Your Invisible aligners will be worn after your initial session. Except when you eat, drink, or brush your teeth, invisible aligners are worn all the time.",
      //         "Two weeks are spent wearing each tray. Your orthodontist or dentists will likely check in with you every six weeks to make sure your treatment is progressing as planned.",
      //       ],
      //     },
      //     {
      //       heading: "Step 5:",
      //       des: [
      //         "Around twelve to eighteen months are required for the entire Invisible Aligners treatment process. Some patients may finish their therapy sooner.",
      //       ],
      //     },
      //   ],
      //   advantages: {
      //     heading: "Advantages of invisible aligners:",
      //     des: [
      //       "Aligners are more comfortable than braces as they are custom-made.",
      //       "Since clear aligners are almost invisible, most people won't even realize you're undergoing treatment while you wear them.",
      //       "As invisible aligners are removable, it is easier to maintain your dental health throughout treatment and keep your teeth clean.",
      //       "You might see results as early as the first month of therapy with invisible braces.",
      //       "Using your invisible aligners, you may whiten your teeth and prevent them from grinding at sleep or when participating in sports (such as weightlifting and mountain riding).",
      //       "Appointments with the dentist are usually simpler and, in certain situations, can even be fewer overall because the tooth movement is pre-planned by your orthodontist.",
      //     ],
      //   },
      // },
      {
        name: "Tooth crowns & bridges",
        img: bridges,
        mainDes: [
          "Crowns are custom-made caps that cover damaged teeth, ",
          "while bridges replace missing teeth by anchoring artificial teeth to neighboring natural teeth or implants.",
        ],
        head: {
          heading: "Dental Crown",
          des: [
            "Dental crowns are the cap like structures which are placed above the tooth serving different functions like chewing, grinding, protection for the underlying teeth, strength to the underlying tooth, esthetics and for smile.",
            "Each crown has their own advantages and disadvantages, but metal free crowns like (zirconium, e max & bruxzir) are more commonly in use now a day.",
            "Factors which decide the cost of these dental crowns/ tooth crowns/ dental caps/crown cost usually vary according to quality of the material, strength of the material & how close it is to the natural teeth.",
          ],
        },
        type: [
          {
            des: [
              "Metal ceramic crowns",
              "Zirconium crowns",
              "E max dental crowns",
              "Bruxzir crowns",
            ],
          },
        ],
        procedure: [
          "Examination: The dentist examines the tooth to ensure it can support a crown, which may involve taking X-rays.",
          "Tooth Preparation: The tooth is reshaped to make room for the crown. If necessary, filling material is used to build up the tooth.",
          "Impression: An impression of the tooth and surrounding teeth is made to ensure proper fit.",
          "Temporary Crown: A temporary crown is placed to protect the prepared tooth while the permanent crown is being made.",
          "Removal of Temporary Crown: The temporary crown is removed, and the tooth is cleaned.",
          "Fitting the Permanent Crown: The dentist checks the fit and color of the permanent crown, making adjustments as needed.",
          "Cementing the Crown: The permanent crown is attached with dental cement, and the dentist checks the bite for alignment.",
        ],
        purpose: [
          "Restoring a Damaged Tooth: Crowns restore teeth that are broken, severely worn down, or damaged by decay.",
          "Protecting a Weak Tooth: Crowns protect teeth weakened by decay or large fillings from breaking.",
          "Supporting a Tooth with a Large Filling: Crowns help hold together teeth with large fillings and little remaining structure.",
          "Covering a Dental Implant: Crowns are placed on implants to replace missing teeth functionally and aesthetically.",
          "Covering Misshaped or Discolored Teeth: Crowns improve the appearance of misshaped or discolored teeth.",
          "Restoring a Tooth after a Root Canal: Crowns restore strength and function to teeth that have undergone root canal treatment.",
        ],
      },
      {
        name: "Dentures",
        img: Dentures,
        mainDes: [
          "Removable prosthetic devices that replace missing teeth and surrounding tissues, providing functional and aesthetic restoration to the mouth.",
        ],
        head: {
          heading: "What are Dentures?",
          des: [
            "Dentures serve as a replacement for missing teeth and surrounding tissues. They are of two types - complete and partial. Complete dentures are advised when all the teeth in the oral cavity are missing, whereas partial dentures are used when some teeth remain.",
            "Dentures are custom-made for each patient and help restore the appearance and functions of the oral cavity. They are made to closely resemble your natural teeth so that your appearance does not change much. When all your teeth are lost facial muscles can sag, making you look older. They help fill out the appearance of your face and facial profile.",
          ],
        },
        type: [
          {
            heading: "The traditional complete denture:",
            des: [
              "Full replacement of both the upper and lower sets of teeth is referred to as a complete denture",
              "They are detachable.",
            ],
          },
          {
            heading: "Partial dentures :",
            des: [
              "When a patient has a few missing teeth, a dentist may recommend partial dentures to replace them.",
              "A removable partial denture is made up of replacement teeth attached to a metal frame that holds the denture in place.",
            ],
          },
          {
            heading: "Immediate dentures :",
            des: [
              "These are prefabricated, that can be placed in your mouth immediately after teeth extraction.",
              "The purpose of these is to replace missing teeth during the healing process.",
            ],
          },
          {
            heading: "Over Dentures :",
            des: [
              "An overdenture is a removable type of denture that sits on the gums and is held in place by dental implants.",
              "Depending on the needs, it can be replaced on either the lower (mandible) or upper (maxillary) jaw.",
            ],
          },
        ],
        procedure: [
          "Initial Consultation: The dentist examines your mouth, takes measurements, and discusses the options for dentures.",
          "Impressions: Molds of your gums and any remaining teeth are taken to create a model for the dentures.",
          "Model Creation: A wax model is made to check the fit and appearance before the final dentures are produced.",
          "Fitting: The final dentures are created and fitted, adjustments are made to ensure comfort and proper function.",
          "Follow-Up: The dentist schedules follow-up visits to make any necessary adjustments and ensure the dentures fit well over time.",
        ],
        purpose: [
          "Restore Function: Dentures allow you to chew and speak more effectively, restoring normal function to your mouth.",
          "Improve Appearance: Dentures fill out your face and profile, giving you a more youthful and natural appearance.",
          "Enhance Confidence: With a full set of teeth, you can smile with confidence without worrying about gaps or missing teeth.",
          "Support Facial Muscles: Dentures provide support to the facial muscles, preventing the sagging appearance that often occurs with missing teeth.",
          "Affordable Solution: Dentures offer an affordable option for replacing multiple missing teeth compared to other dental treatments like implants or bridges.",
        ],
      },
      {
        name: "Tooth cleaning & Gum disease",
        img: disease,
        mainDes: [
          "Professional removal of plaque and tartar from teeth surfaces to prevent cavities and maintain oral health.",
          "Treatment involves scaling and root planing to remove bacteria and tartar from below the gum line, promoting gum tissue healing and preventing further damage.",
        ],
        head: {
          heading: "What is Tooth Cleaning?",
          des: [
            "Tooth cleaning, also known as dental prophylaxis, is a professional cleaning procedure performed by a dentist or dental hygienist to maintain oral hygiene and prevent dental issues.",
            "Regular tooth cleanings are essential for removing plaque, tartar, and stains that cannot be removed by regular brushing and flossing.",
          ],
        },
        type: [
          {
            heading: "Tooth Cleaning (Prophylaxis)",
            sub: [
              {
                heading: "procedure",
                des: [
                  "Examination: The dentist or dental hygienist examines your mouth for signs of oral health issues, such as cavities or gum disease.",
                  "Scaling: Using special tools, the hygienist removes plaque and tartar from the surfaces of your teeth, especially around the gum line and between teeth.",
                  "Polishing: After scaling, the teeth are polished to remove surface stains and smooth the tooth surface.",
                  "Fluoride Treatment: In some cases, a fluoride treatment is applied to strengthen the teeth and help prevent cavities.",
                ],
              },
              {
                heading: "purpose",
                des: [
                  "Prevent Cavities: By removing plaque and tartar, the risk of developing cavities is reduced.",
                  "Prevent Gum Disease: Regular cleanings help prevent gingivitis and periodontitis.",
                  "Freshen Breath: Cleaning removes odor-causing bacteria.",
                  "Improve Appearance: Polishing helps remove surface stains, enhancing the appearance of your teeth.",
                  "Detect Oral Health Issues: Regular examinations during cleaning can identify problems early.",
                ],
              },
            ],
          },
          {
            heading: "Gum Disease Treatment",
            sub: [
              {
                heading: "procedure",
                des: [
                  "Initial Evaluation: The dentist assesses the severity of the gum disease through examination, measuring the depth of the gum pockets, and sometimes using X-rays.",
                  "Scaling and Root Planing: A deep cleaning procedure that involves removing plaque and tartar from below the gum line and smoothing the root surfaces to promote healing.",
                  "Antibiotic Therapy: In some cases, antibiotics are used to help control bacterial infection.",
                  "Surgical Treatments: For advanced gum disease, procedures such as flap surgery, bone grafts, or tissue grafts may be necessary.",
                  "Maintenance: After treatment, regular dental visits and good oral hygiene are critical to maintain health.",
                ],
              },
              {
                heading: "purpose",
                des: [
                  "Reduce Inflammation: Treatment aims to reduce gum inflammation and swelling.",
                  "Prevent Tooth Loss: By treating gum disease, the structures that support the teeth (gums, bone) are preserved.",
                  "Alleviate Discomfort: Treatment can reduce pain and discomfort associated with gum disease.",
                  "Improve Oral Hygiene: Post-treatment education and maintenance help maintain healthy gums and teeth.",
                  "Prevent Systemic Health Issues: Untreated gum disease has been linked to systemic health problems such as heart disease and diabetes, so treating it can improve overall health.",
                ],
              },
            ],
          },
        ],
      },
      // {
      //   name: "Extraction of teeth",
      //   img: Extraction,
      //   head: {
      //     heading: "What is Tooth Extraction?",
      //     des: [
      //       "Tooth extraction is a dental procedure where a tooth is removed from its socket in the bone. It is typically performed by a dentist or oral surgeon under local or general anesthesia.",
      //       "Extractions are necessary for several reasons, including severe tooth decay, advanced gum disease, crowding, trauma, or when a tooth cannot be saved by other treatments like root canal therapy.",
      //       "The procedure aims to alleviate pain, prevent the spread of infection, and preserve overall oral health.",
      //     ],
      //   },
      //   procedure: [
      //     "Examination: The dentist or oral surgeon examines your mouth, reviews your dental and medical history, and takes X-rays to understand the position of the tooth and surrounding bone.",
      //     "Anesthesia: Local anesthesia is administered to numb the area around the tooth. In some cases, general anesthesia or sedation may be used.",
      //     "Loosening the Tooth: The dentist or oral surgeon uses an instrument called an elevator to loosen the tooth from the gums and jawbone.",
      //     "Extraction: Once loosened, forceps are used to remove the tooth from the socket.",
      //     "Post-Extraction Care: Gauze is placed over the extraction site to control bleeding. The patient is given instructions for home care, including pain management, swelling reduction, and diet restrictions.",
      //   ],
      //   purpose: [
      //     "Remove Damaged Tooth: Extraction removes teeth that are severely decayed, broken, or damaged beyond repair.",
      //     "Prevent Infection: Removing infected teeth prevents the spread of infection to other teeth and areas of the mouth.",
      //     "Alleviate Pain: Extraction can relieve pain caused by problematic teeth, such as impacted wisdom teeth.",
      //     "Prepare for Orthodontics: Sometimes teeth are extracted to make room for proper alignment during orthodontic treatment.",
      //     "Resolve Overcrowding: Extraction can address overcrowding issues, allowing for better oral hygiene and overall dental health.",
      //   ],
      // },
      {
        name: "Child dental treatment",
        img: Child,
        mainDes: [
          "Focuses on preventive care, including regular check-ups, fluoride treatments,",
          "sealants, and education on proper oral hygiene habits to ensure healthy teeth and gums from an early age.",
        ],
        head: {
          heading: "What is Teeth Whitening?",
          des: [
            "Teeth whitening is a cosmetic dental procedure that lightens and brightens the color of your teeth.",
            "It is typically done to remove stains and discoloration caused by aging, smoking, certain foods and drinks, or poor dental hygiene.",
            "Teeth whitening can be performed by a dentist in-office or at home using whitening kits prescribed by a dentist.",
          ],
        },
        procedure: [
          "Examination: The dentist examines the child's mouth, teeth, and gums, and may take X-rays to check for cavities and other issues.",
          "Cleaning: A dental hygienist cleans the child's teeth, removing plaque and tartar, and polishes the teeth.",
          "Fluoride Treatment: A fluoride varnish or gel is applied to the child's teeth to strengthen enamel and help prevent cavities.",
          "Sealants: If necessary, dental sealants are applied to the chewing surfaces of the back teeth to protect them from decay.",
          "Education: The dentist and hygienist educate the child and parents about proper brushing, flossing, and diet to maintain good oral health.",
        ],
        purpose: [
          "Prevent Cavities: Regular cleanings and fluoride treatments help prevent cavities in children’s teeth.",
          "Promote Good Oral Hygiene: Education on proper brushing and flossing techniques helps children develop good oral hygiene habits.",
          "Monitor Development: Regular dental visits allow the dentist to monitor the growth and development of the child's teeth and jaw.",
          "Early Detection: Regular check-ups enable early detection of dental issues such as cavities, misalignment, and gum problems.",
          "Build Positive Attitude: Routine visits to the dentist help children become comfortable with dental care and reduce dental anxiety.",
        ],
      },
      {
        name: "Dental X- Ray",
        img: XRay,
        mainDes: [
          "Diagnostic imaging technique used to capture detailed images of teeth, gums, and jawbones to assess oral health and detect dental issues such as cavities, infections, and bone loss.",
        ],
        head: {
          heading: "What are Dental X-Rays?",
          des: [
            "Dental X-rays are diagnostic imaging tools used by dentists to visualize the structures inside and around the teeth.",
            "They provide detailed images of the teeth, gums, jawbone, and surrounding tissues that are not visible during a regular dental examination.",
            "Dental X-rays help dentists diagnose dental conditions such as cavities, infections, bone loss, and abnormalities in tooth and jaw positioning.",
          ],
        },
        procedure: [
          "Preparation: The patient is covered with a lead apron to protect the body from radiation exposure, and a thyroid collar may be used to protect the thyroid gland.",
          "Positioning: The patient is positioned correctly, and a dental X-ray sensor or film is placed in the mouth.",
          "Taking the X-Ray: The dentist or dental assistant positions the X-ray machine and takes the X-ray. The patient needs to stay still for a few seconds during the exposure.",
          "Developing the X-Ray: Digital X-rays appear instantly on a computer screen, while traditional film X-rays are developed in a darkroom or with an automatic processor.",
          "Review and Diagnosis: The dentist reviews the X-rays to diagnose any issues such as cavities, bone loss, or other dental conditions.",
        ],
        purpose: [
          "Detect Cavities: X-rays help in identifying cavities that are not visible during a regular dental examination.",
          "Monitor Bone Health: They are used to assess the health of the bone supporting the teeth.",
          "Evaluate Tooth Development: For children, X-rays are essential to monitor the development of teeth and to check for any abnormalities.",
          "Plan Treatments: X-rays are critical for planning dental treatments such as braces, implants, and extractions.",
          "Identify Infections or Disease: X-rays can reveal infections, cysts, tumors, and other diseases affecting the teeth and jawbones.",
          "Diagnosis: Detects dental problems such as cavities, infections, gum disease, and impacted teeth.",
          "Treatment Planning: Provides dentists with detailed information to plan treatments like fillings, root canals, and orthodontic care.",
          "Preventive Care: Allows early detection of dental issues, preventing complications and preserving oral health.",
        ],
      },

      {
        name: "Cosmetic dentistry",
        img: dentistry,
        mainDes: [
          "Focuses on improving the aesthetic appearance of teeth, gums, and smile through procedures such as teeth whitening, veneers, crowns, and dental implants.",
        ],
        head: {
          des: [
            "Cosmetic dentistry is the branch of dentistry that aims to improve the color, shape, alignment, and overall appearance of your teeth.",
            "A cosmetic dentist can provide services that go far beyond what most people expect, such as veneers and teeth whitening. You can improve your appearance, smile, and even your self-esteem and confidence with the help of a qualified, experienced cosmetic dentist.",
            "As our cosmetology services have advanced, dentists can now address patient's practical and aesthetic issues and provide simple and sophisticated solutions for improving their smile, depending on the patient’s desired result.",
          ],
        },
        treatment: [
          {
            heading: "Teeth Whitening:",
            des: [
              "Teeth whitening is a cosmetic treatment done to improve the appearance of teeth. Whitening may be accomplished by using a bleaching agent such as peroxide. The procedure may be carried out in the dental office, at home with guidance from a dentist, or at home with the use of over-the-counter products.",
            ],
          },
          {
            heading: "Porcelain Crowns  :",
            des: [
              "Dental porcelain can be sculpted to closely resemble natural tooth enamel while also providing desirable strength and resilience.",
            ],
          },
          {
            heading: "Periodontal Plastic Surgery:",
            des: [
              "Periodontal plastic surgery is used to reshape the gum tissue, periodontal ligament, and bone that support your teeth.",
            ],
          },
          {
            heading: "Veneers:",
            des: [
              "Veneers are extremely thin porcelain laminates that are customized and permanently bonded to the teeth. Some degree of tooth reduction is required to achieve the best results and give you a pleasant smile.",
            ],
          },
          {
            heading: "Tooth Jewelry:",
            des: [
              "Tooth jewelry is an accessory that is worn on the front of your tooth. This fashion trend allows you to express your individuality through your smile, making it more distinctive and fashionable.",
            ],
          },
        ],
      },
      {
        name: "Dental Veneers & Laminates",
        img: DentalVeneers,
        mainDes: [
          "Thin, custom-made shells of tooth-colored materials that cover the front surface of teeth to improve appearance by addressing issues like discoloration, chips, or gaps.",
        ],
      },
      {
        name: "Teeth whitening",
        img: demoImage,
        mainDes: [
          "Involves bleaching the teeth to lighten discoloration and stains, enhancing the overall appearance of the smile.",
        ],
        head: {
          heading: "What is teeth whitening?",
          des: [
            "Teeth whitening is a cosmetic dental procedure that aims to lighten the color of teeth and remove stains and discoloration.",
            "Some of the most common methods for whitening teeth include bleaching with hydrogen peroxide or carbamide peroxide and using abrasive materials to remove surface stains. It is important to note that teeth whitening is not permanent and may require touch-ups or maintenance over time. It is also important to consult with a dental professional before undergoing any teeth whitening treatment.",
          ],
        },
        need: {
          heading: "Who needs teeth whitening?",
          des: [
            "Smoking, excess consumption of coffee, strong tea, red wine, and products containing strong dyes all cause the enamel to darken. Unwanted pigmentation accumulates and changes the color of teeth, even those that are perfectly healthy. Bleaching aids in dealing with this issue.",
            "A desire for white teeth.",
            "Mild to moderate fluorosis.",
            "Age-related changes.",
            "Certain medical treatments can also cause tooth staining.",
          ],
          procedure: [
            "First, we will remove the film that has formed on your enamel as a result of the food you eat or other substances.",
            "Following this, the bleaching procedure begins. The entire procedure takes between 30-90 minutes, depending on the severity of the stains on the teeth.",
            "We will cover the gums before applying the bleaching agent to the teeth. Some bleaching agents necessitate the use of laser lights, and if your teeth are severely stained, we may advise you to continue the process at home for a few days.",
          ],
          post: {
            heading: "Post care after teeth whitening:",
            des: [
              "Avoid drinking strongly colored beverages after your whitening treatment.",
              "Don’t eat foods that stain.",
              "It is advised to stop smoking and/or chewing not only for health reasons, but also to keep your teeth pearly white.",
              "Avoid coloured dental hygiene products.",
            ],
          },
        },
        procedure: [
          "Examination: The dentist examines your teeth and gums to determine if you are a candidate for teeth whitening.",
          "Cleaning: If necessary, a dental cleaning may be performed to remove surface stains and plaque before whitening.",
          "Whitening Treatment: In-office treatments involve applying a bleaching agent directly to the teeth, which is activated by a special light or laser.",
          "At-Home Kits: Dentist-prescribed kits typically use custom-fitted trays filled with a whitening gel that you wear for a specified period.",
          "Follow-Up: After treatment, the dentist may provide instructions for maintaining results and scheduling follow-up visits.",
        ],
        purpose: [
          "Remove Stains and Discoloration: Whitening treatments effectively lift stains caused by coffee, tea, wine, tobacco, and natural aging.",
          "Enhance Smile Appearance: Brighter teeth can improve the overall appearance of your smile and boost self-confidence.",
          "Customizable Options: Dentists can tailor treatments to your needs, offering both in-office procedures and take-home kits for convenience.",
          "Safe and Controlled Application: Professional whitening ensures that the procedure is performed safely, minimizing potential risks to gums and tooth enamel.",
        ],
      },
      {
        name: "Tooth Jewellery",
        img: ToothJewellery,
        mainDes: [
          "Decorative gems or ornaments bonded to the surface of teeth for cosmetic enhancement, often using safe dental adhesive methods.",
        ],
      },
    ],
  },
  {
    logo: <FaUserDoctor />,
    title: "Dental Implants & Oral Surgery",
    img: serviceTwo,
    mainImg: serviceMainTwo,
    mainDes:
      "Dental Implants are now the preferred choice for restoring missing teeth.",
    mainSubDes: [
      "Helps keep the jawbone from shrinking due to bone loss.",
      "Preserves the health of the surrounding bone and gums.",
      "Helps keep adjacent (nearby) teeth stable",
    ],
    des: [
      "Dental procedures can help you with pain, infection, an injury or other problems with your teeth or gums. Dental procedures include: a filling or crown for your tooth, treatment for gum disease, straightening crowded teeth, dental surgery or having dentures made.",
      "Specialist dentist at the clinic include Orthodontist (braces & aligners), Endodontists (root canals), Prosthodontist (crown, bridge & dentures), Paediatric dentists (dental services for children), Periodontists (gum disease).",
    ],
    subHead: [
      {
        name: "Dental Implants",
        subTitle: [
          {
            name: "Dental Implants",
            img: DentalImplants,
            mainDes: [
              "Biocompatible titanium implants are placed in the jaw bone.",
              "They act like tooth roots to support one or more false teeth",
            ],
          },
          {
            name: "Implant-supported dentures",
            img: ImplantSupported,
            mainDes: [
              "If you don’t like removable dentures, Implant Supported Dentures is a convenient alternative.",
              "Dentures are held in place by 2 or more implants",
            ],
            head: {
              heading: "What are Implant-Supported Dentures?",
              des: [
                "Implant-supported dentures are a type of overdenture that is supported by and attached to dental implants.",
                "Unlike traditional dentures that rest on the gums, implant-supported dentures are anchored securely to implants surgically placed in the jawbone.",
                "They provide greater stability, comfort, and functionality compared to removable dentures.",
              ],
            },
            procedure: [
              "Initial Consultation: The dentist evaluates the patient's oral health, discusses the treatment options, and takes X-rays or 3D scans to assess bone structure.",
              "Treatment Planning: A detailed treatment plan is created, which includes the number and placement of implants needed to support the dentures.",
              "Bone Grafting (if necessary): If there is insufficient bone to support the implants, a bone graft may be performed to build up the bone structure.",
              "Implant Placement: Titanium implants are surgically placed into the jawbone. This may involve multiple implants depending on the denture design.",
              "Osseointegration: The implants are left to integrate with the jawbone over a period of several months, ensuring they are stable and secure.",
              "Abutment Placement: Once osseointegration is complete, abutments are attached to the implants. These abutments will connect the implants to the dentures.",
              "Denture Fabrication: Impressions of the mouth are taken to create custom dentures that fit securely over the implants.",
              "Denture Attachment: The custom-made dentures are attached to the abutments, providing a stable and secure fit.",
            ],
            purpose: [
              "Stability and Security: Implant-supported dentures provide greater stability and security compared to traditional dentures, reducing slippage and improving comfort.",
              "Preserve Jawbone: Implants help maintain jawbone structure and prevent the bone loss that typically occurs with missing teeth and traditional dentures.",
              "Improved Function: They restore the ability to chew and speak more effectively, enhancing overall oral function.",
              "Enhanced Aesthetics: Implant-supported dentures look more natural and provide a better aesthetic appearance than traditional dentures.",
              "Increased Confidence: The stability and improved function of implant-supported dentures can significantly boost a patient's confidence and quality of life.",
            ],
          },
          {
            name: "Zygomatic implants",
            img: Zygomatic,
            mainDes: [
              "When the quality or quantity of the maxillary bone is inadequate for implant placement,",
              "Implants are anchored into the zygomatic bone.",
            ],
            head: {
              heading: "what is Zygomatic implants",
              des: [
                "Zygomatic implants are a type of dental implant used for patients with insufficient bone in the upper jaw. They are longer than traditional implants and anchor into the zygomatic bone (cheekbone) rather than the maxillary bone (upper jawbone). This makes them a viable option for those who cannot undergo bone grafting or who need a more immediate solution for dental restoration.",
              ],
            },
            procedure: [
              "Initial Consultation: The dentist or oral surgeon evaluates the patient's oral health, discusses treatment options, and takes X-rays or 3D scans to assess bone structure and plan the procedure.",
              "Treatment Planning: A comprehensive treatment plan is created, which includes the positioning of the zygomatic implants.",
              "Preparation: The patient is prepared for surgery, which involves administering local or general anesthesia.",
              "Implant Placement: Unlike traditional implants placed in the jawbone, zygomatic implants are anchored in the zygomatic bone (cheekbone). This is done through a surgical procedure.",
              "Osseointegration: The implants are left to integrate with the zygomatic bone over a period of several months, ensuring stability and support.",
              "Abutment Placement: Once osseointegration is complete, abutments are attached to the zygomatic implants.",
              "Prosthesis Attachment: The final prosthesis, which can be a denture or fixed bridge, is attached to the abutments, completing the restoration.",
            ],
            purpose: [
              "Alternative for Severe Bone Loss: Zygomatic implants provide a solution for patients with severe bone loss in the upper jaw who are not candidates for traditional implants.",
              "Stability and Support: They offer strong support and stability for dental prostheses, improving function and comfort.",
              "Avoid Bone Grafting: These implants often eliminate the need for bone grafting procedures, reducing overall treatment time.",
              "Enhanced Aesthetics: Zygomatic implants help restore the natural appearance of the teeth and face.",
              "Improved Quality of Life: By providing a stable foundation for dental prostheses, zygomatic implants enhance the patient's ability to eat, speak, and smile with confidence.",
            ],
          },
          {
            name: "Bone grafting",
            img: BoneGrafting,
            mainDes: [
              "Procedure performed to increase the amount of bone in any part of the jaw where bone has been lost or where additional support is needed",
            ],
            head: {
              heading: "what is Bone grafting",
              des: [
                "Bone grafting is a surgical procedure used to replace or augment missing bone in the jaw. This is essential for patients who have experienced bone loss due to periodontal disease, trauma, or long-term tooth loss. The grafted bone can come from the patient's own body (autograft), a donor (allograft), an animal source (xenograft), or be synthetically produced. The goal is to rebuild the bone structure to support dental implants or other dental restorations.",
              ],
            },
            procedure: [
              "Initial Consultation: The dentist or oral surgeon evaluates the patient's oral health, reviews medical history, and takes X-rays or 3D scans to assess bone loss.",
              "Treatment Planning: A treatment plan is created based on the type and extent of bone graft needed.",
              "Preparation: The patient is prepared for surgery, which may involve administering local or general anesthesia.",
              "Harvesting Bone (if using autograft): Bone is harvested from another part of the patient's body, such as the hip, or from a donor site within the mouth.",
              "Grafting Material Placement: The bone graft material (autograft, allograft, xenograft, or synthetic bone) is placed in the area with bone loss.",
              "Stabilization: The graft is stabilized using membranes, screws, or plates to ensure it stays in place during healing.",
              "Healing: Over several months, the bone graft integrates with the existing bone, creating a stable foundation for future implants or other dental work.",
            ],
            purpose: [
              "Restore Bone Volume: Bone grafting restores lost bone volume, making it possible to place dental implants or other restorations.",
              "Support Dental Implants: It provides a stable and sufficient bone structure to support dental implants.",
              "Preserve Facial Structure: By maintaining or restoring bone volume, bone grafting helps preserve the facial structure and aesthetics.",
              "Prevent Bone Loss: Grafting can prevent further bone loss after tooth extraction or trauma.",
              "Enhance Functionality: Restored bone allows for improved function, such as better chewing and speaking abilities.",
            ],
          },
          {
            name: "Sinus lift",
            img: Sinus,
            mainDes: [
              "When you desire dental implants but the quantity of the upper jaw bone is inadequate to secure the implant,",
              "sinus lift procedure increases the level of the bone",
            ],
            head: {
              heading: "what is Sinus lift?",
              des: [
                "A sinus lift, also known as sinus augmentation, is a surgical procedure used to increase the amount of bone in the posterior maxilla (upper jaw). This procedure is necessary when there is insufficient bone height in the jaw to place dental implants. During the procedure, the sinus membrane is lifted to make room for bone graft material. This material serves as a scaffold where new bone can grow, eventually providing enough bone structure to support dental implants.",
              ],
            },
            procedure: [
              "Initial Consultation: The dentist or oral surgeon evaluates the patient's oral health, reviews medical history, and takes X-rays or CT scans to assess bone structure and sinus anatomy.",
              "Treatment Planning: A comprehensive treatment plan is created based on the evaluation, determining the type and extent of sinus lift needed.",
              "Preparation: The patient may be prescribed antibiotics before surgery to prevent infection. Local or general anesthesia is administered for comfort.",
              "Incision and Access: An incision is made in the gum tissue to expose the underlying bone. A small window is then created in the bone to access the sinus cavity.",
              "Lifting the Sinus Membrane: The sinus membrane is gently lifted upward from the jawbone, creating a space for the bone graft material.",
              "Bone Graft Placement: Bone graft material (autograft, allograft, xenograft, or synthetic bone) is placed into the space created above the sinus.",
              "Closure: The incision is closed with stitches. Depending on the specific procedure, healing may take several months for the graft to integrate with the existing bone.",
              "Follow-up: Post-operative visits are scheduled to monitor healing and ensure the success of the procedure.",
            ],
            purpose: [
              "Increase Bone Height: Sinus lift procedures are performed to augment bone height in the posterior maxilla (upper jaw), providing adequate bone structure for dental implants.",
              "Support Dental Implants: The additional bone volume supports the stability and long-term success of dental implants.",
              "Restore Function: By enhancing bone volume, sinus lifts restore the ability to chew and speak effectively, improving overall oral function.",
              "Preserve Facial Structure: Augmenting bone height helps maintain facial contours and aesthetics, preventing the appearance of a sunken-in or aged facial profile.",
              "Enable Treatment Options: Sinus lifts make it possible for patients who have experienced bone loss in the posterior maxilla to receive dental implant-supported restorations.",
            ],
          },
        ],
      },
      {
        name: "Oral Surgery",
        subTitle: [
          {
            name: "Removing (extracting) teeth",
            img: Extraction,
            mainDes: [
              "The procedure for extracting teeth typically involves numbing the area with local anesthesia,",
              "loosening the tooth with specialized instruments, and then carefully removing it from the socket.",
            ],
          },
          {
            name: "Wisdom tooth extractions",
            img: WisdomTooth,
            mainDes: ["Surgical procedure to remove one or more wisdom teeth"],
            head: {
              heading: "What is Wisdom Tooth Extraction?",
              des: [
                "Wisdom tooth extraction is a surgical procedure to remove one or more wisdom teeth, also known as third molars.",
                "These teeth are located at the back of the mouth and often need to be removed due to impaction, crowding, or potential dental problems.",
                "Extraction may be recommended by a dentist or oral surgeon to prevent pain, infection, or damage to adjacent teeth.",
              ],
            },
            procedure: [
              {
                heading: "Consultation and Examination",
                des: [
                  "Evaluation: The dentist assesses the position and condition of the wisdom teeth using X-rays.",
                  "Treatment Plan: Based on the evaluation, the dentist discusses the need for extraction and explains the procedure.",
                ],
              },
              {
                heading: "Extraction Procedure",
                des: [
                  "Anesthesia: Local anesthesia, sedation, or general anesthesia is administered for comfort during the procedure.",
                  "Tooth Removal: The tooth is loosened from its socket and carefully extracted using forceps or surgical instruments.",
                  "Stitching: Stitches may be placed to close the extraction site and promote healing.",
                  "Aftercare: Post-operative instructions are provided to manage swelling, pain, and prevent complications.",
                ],
              },
            ],
            purpose: [
              "Prevent Impaction: Removes impacted wisdom teeth that are trapped in the jawbone or gums.",
              "Prevent Crowding: Eliminates overcrowding caused by wisdom teeth pushing against neighboring teeth.",
              "Prevent Infection: Reduces the risk of gum inflammation (pericoronitis) and infection around partially erupted wisdom teeth.",
              "Alleviate Pain: Relieves pain and discomfort associated with impacted or infected wisdom teeth.",
              "Protect Oral Health: Prevents potential damage to adjacent teeth and maintains overall oral health.",
            ],
          },
          {
            name: "Minor Oral Surgery",
            img: MinorOral,
            mainDes: ["in place of chronic oral pain and oral diseases"],
          },
          // {
          //   name: "Chronic dental pain",
          //   img: ChronicDental,
          //   mainDes: [
          //     "Chronic dental pain is a persistent, long-lasting discomfort in the teeth or gums, often caused by conditions like tooth decay, gum disease, or nerve damage. ",
          //     "It requires professional diagnosis and treatment to alleviate the pain and address the underlying issue.",
          //   ],
          // },
          // {
          //   name: "Oral diseases",
          //   img: AbscessDrainage,
          //   mainDes: [
          //     "Remove decayed tissue, clean the cavity, and fill it with dental material.",
          //     "Perform scaling and root planing to remove plaque and tartar, followed by proper oral hygiene practices.",
          //   ],
          // },
        ],
      },
    ],
  },
  {
    logo: <FaSuitcaseMedical />,
    title: "Maxillofacial Surgery",
    img: serviceThree,
    mainImg: serviceMainThree,
    mainDes:
      '"For patients in need of surgical procedures and in-patient care, these essential services are offered at our affiliated hospitals, which are equipped with operating theatres."',
    des: [
      "Oral and maxillofacial surgery is a surgical specialty focusing on reconstructive surgery of the face, facial trauma surgery, the oral cavity, head and neck, mouth, and jaws, as well as facial cosmetic surgery. Dental implantology",
      "We provide out patient services at the clinic and in patient services at the nearby hospitals.",
    ],
    subTitle: [
      {
        name: "Facial trauma",
        subName: "(Facial Fractures or Damaged Maxillofacial Tissue)",
        mainDes: [
          "Maxillofacial trauma refers to any injury to the face or jaws.",
          "This type of trauma can be caused by: motor vehicle accidents, falls, sports injuries, interpersonal violence, and work-related accidents.",
        ],
        mainDesTwo: {
          heading: "Patients may exhibit symptoms such as:",
          des: [
            "Facial lacerations",
            "Intra-oral lacerations",
            "Avulsed (knocked out) teeth",
            "Fractured facial bones (cheek, nose or eye socket)",
            "Fractured jaws (upper and lower jaw)",
          ],
        },

        img: FacialTrauma,
        head: {
          heading: "What is Facial Trauma?",
          des: [
            "Facial trauma refers to any injury to the face, including the skin, bones, muscles, and teeth.",
            "These injuries can result from accidents, falls, sports injuries, assaults, or other traumatic events.",
            "Treatment of facial trauma depends on the type and severity of the injury, ranging from minor cuts and bruises to fractures and dislocations.",
          ],
        },
        procedure: [
          {
            heading: "Assessment and Diagnosis",
            des: [
              "Initial Evaluation: The healthcare provider assesses the extent of facial injuries and checks for associated injuries.",
              "Imaging: X-rays, CT scans, or MRI may be performed to evaluate fractures or internal injuries.",
            ],
          },
          {
            heading: "Treatment Options",
            des: [
              "Wound Care: Cleaning and suturing of cuts and lacerations to promote healing and reduce infection risk.",
              "Fracture Repair: Surgical procedures to realign and stabilize fractured facial bones using plates, screws, or wires.",
              "Soft Tissue Repair: Repair of soft tissue injuries, including facial nerve repair or reconstruction.",
            ],
          },
          {
            heading: "Recovery and Rehabilitation",
            des: [
              "Post-Procedure Care: Instructions for wound care, pain management, and monitoring for complications.",
              "Physical Therapy: Rehabilitation to restore facial function, such as chewing, speaking, and facial expression.",
              "Follow-Up: Regular follow-up visits to monitor healing progress and assess long-term outcomes.",
            ],
          },
        ],
        purpose: [
          "Restore Function: Treatment aims to restore normal function of the face, including chewing, breathing, and sensory functions.",
          "Cosmetic Restoration: Addressing facial injuries to improve appearance and restore facial symmetry.",
          "Prevent Complications: Early treatment helps prevent complications such as infection, nerve damage, or facial deformities.",
          "Psychological Support: Addressing the emotional impact of facial trauma and supporting patients through recovery.",
        ],
      },
      {
        name: "Temporomandibular Joint (TMJ) disorders",
        subName: "(Jaw Joint problems)",
        img: Temporomandibular,
        mainDes: [
          "Temporomandibular joint (TMJ) concerns refer to problems affecting the jaw joint and the muscles that control jaw movement.",
          "Symptoms: pain, discomfort, clicking or popping noises, and difficulty with jaw movement.",
          "Cause: jaw injury, arthritis, teeth grinding (bruxism), or misalignment of the jaw or teeth.",
        ],
        note:"To note: The TM Joint is very delicate and highly susceptible to stress. The pain can radiate to the neck and shoulder muscles in severe cases.",
        head: {
          heading: "What are Temporomandibular Joint (Jaw Joint) Concerns?",
          des: [
            "Temporomandibular joint (TMJ) concerns refer to problems affecting the jaw joint and the muscles that control jaw movement.",
            "These concerns can include pain, discomfort, clicking or popping noises, and difficulty with jaw movement.",
            "Causes of TMJ concerns may include jaw injury, arthritis, teeth grinding (bruxism), or misalignment of the jaw or teeth.",
          ],
        },
        procedure: [
          {
            heading: "Assessment and Diagnosis",
            des: [
              "Clinical Examination: The dentist examines the jaw joint, muscles, and teeth for signs of TMJ concerns.",
              "Imaging: X-rays, MRI, or CT scans may be used to assess the structure of the jaw joint and surrounding tissues.",
            ],
          },
          {
            heading: "Treatment Options",
            des: [
              "Pain Management: Medications, heat or ice packs, and relaxation techniques to alleviate pain and discomfort.",
              "Splint Therapy: Custom-made oral appliances (splints or mouthguards) to reduce jaw clenching or teeth grinding.",
              "Physical Therapy: Exercises to strengthen jaw muscles and improve range of motion.",
              "Orthodontic Treatment: Correcting misalignment of teeth or jaw to alleviate TMJ symptoms.",
              "Surgery: In severe cases, surgical procedures may be recommended to repair or replace the jaw joint.",
            ],
          },
          {
            heading: "Self-Care and Prevention",
            des: [
              "Stress Management: Techniques to reduce stress, which can contribute to jaw clenching and TMJ symptoms.",
              "Dietary Changes: Avoiding hard or chewy foods that strain the jaw joint.",
              "Good Oral Hygiene: Maintaining oral health to prevent tooth and gum problems that can aggravate TMJ concerns.",
            ],
          },
        ],
        purpose: [
          "Relieve Pain and Discomfort: Treatment aims to alleviate symptoms such as pain, clicking, and restricted jaw movement.",
          "Improve Jaw Function: Enhancing the ability to chew, speak, and move the jaw comfortably.",
          "Prevent Further Damage: Addressing underlying causes to prevent worsening of TMJ concerns.",
          "Enhance Quality of Life: Helping patients regain normal jaw function and reduce discomfort in daily activities.",
        ],
      },
      {
        name: "Oral Cancer",
        subName: "(Can develop in any part of the Mouth)",
        img: OralCancer,
        mainDes: [
          "Oral cancer may develop in any part of the mouth, including the lips, tongue, gums, floor of the mouth, and the roof of the mouth (palate).It can also affect the salivary glands",
          "Early detection and treatment of oral cancer are crucial for improving outcomes and reducing complications.",
        ],
        note:"To note: The presence of a non-healing ulcer or sharp tooth trauma/wound in the mouth requires immediate attention.",
        head: {
          heading: "What is Oral Cancer?",
          des: [
            "Oral cancer refers to cancer that develops in any part of the mouth, including the lips, tongue, gums, floor of the mouth, and the roof of the mouth (palate).",
            "It can also affect the throat, tonsils, and salivary glands. Oral cancer usually begins in the flat cells (squamous cells) lining the surfaces of the mouth.",
            "Early detection and treatment of oral cancer are crucial for improving outcomes and reducing complications.",
          ],
        },
        procedure: [
          {
            heading: "Screening and Diagnosis",
            des: [
              "Visual Examination: The dentist examines the mouth and throat for signs of abnormalities such as sores, white or red patches, or lumps.",
              "Biopsy: Tissue samples (biopsy) may be taken from suspicious areas for laboratory analysis to confirm the presence of cancerous cells.",
            ],
          },
          {
            heading: "Treatment Options",
            des: [
              "Surgery: Surgical removal of the cancerous tissue, possibly including surrounding healthy tissue to ensure complete removal.",
              "Radiation Therapy: High-energy beams are used to target and kill cancer cells.",
              "Chemotherapy: Drugs are administered to kill cancer cells or stop their growth.",
              "Targeted Therapy: Drugs specifically target abnormalities in cancer cells while minimizing damage to normal cells.",
            ],
          },
          {
            heading: "Rehabilitation and Follow-Up",
            des: [
              "Reconstructive Surgery: Restoring the appearance and function of the mouth and jaw after surgery.",
              "Speech and Swallowing Therapy: Rehabilitation to improve speech and swallowing abilities post-treatment.",
              "Regular Follow-Up: Monitoring for recurrence and managing side effects of treatment.",
            ],
          },
        ],
        purpose: [
          "Early Detection: Screening and early diagnosis increase the chances of successful treatment and survival.",
          "Effective Treatment: Tailored treatment plans aim to eradicate cancer cells while preserving oral function.",
          "Improving Quality of Life: Rehabilitation and support services help patients cope with treatment side effects and maintain oral health.",
          "Preventive Education: Promoting awareness of risk factors and encouraging preventive behaviors to reduce the risk of oral cancer.",
        ],
      },
      {
        name: "Cysts & Tumors",
        subName: "(May present in any part of the Face, Jaw & Mouth)",
        mainDes: [
          "Cysts & Tumors of the Face, Jaw & Mouth may also involve  salivary glands, sinuses, and other structures in the head and neck region.",
          
        ],
        note:"To Note: Any painless swelling on the face that gradually increases in size needs to be evaluated.",
        img: PathologyTumour,
        head: {
          heading: "What is Head and Neck Pathology/Tumor?",
          des: [
            "Head and neck pathology refers to the study and diagnosis of diseases, disorders, and tumors affecting the structures of the head and neck.",
            "These may include tumors of the mouth, throat, salivary glands, sinuses, thyroid gland, and other structures in the head and neck region.",
            "Diagnosis and treatment of head and neck tumors require careful evaluation and often involve a multidisciplinary approach.",
          ],
        },
        procedure: [
          {
            heading: "Diagnostic Procedures",
            des: [
              "Biopsy: Tissue samples are taken from suspicious areas for laboratory analysis to confirm the presence of tumors.",
              "Imaging Studies: CT scans, MRI, PET scans, and ultrasound may be used to visualize and determine the extent of tumors.",
              "Endoscopy: Examination of the internal structures of the head and neck using a thin, flexible tube with a camera.",
            ],
          },
          {
            heading: "Treatment Options",
            des: [
              "Surgery: Surgical removal of tumors and affected tissues, sometimes followed by reconstructive surgery.",
              "Radiation Therapy: High-energy beams are used to target and destroy cancer cells.",
              "Chemotherapy: Drugs are administered to kill cancer cells or stop their growth.",
              "Targeted Therapy: Drugs target specific molecules involved in tumor growth.",
            ],
          },
          {
            heading: "Rehabilitation and Support",
            des: [
              "Reconstructive Surgery: Restoring the appearance and function of the head and neck after surgery.",
              "Speech and Swallowing Therapy: Rehabilitation to improve speech and swallowing abilities post-treatment.",
              "Psychological Support: Counseling and support services to address emotional and psychological aspects of treatment.",
            ],
          },
        ],
        purpose: [
          "Early Detection: Timely diagnosis improves treatment outcomes and survival rates.",
          "Effective Treatment: Tailored treatment plans aim to eradicate tumors while preserving function and minimizing side effects.",
          "Rehabilitation: Support services help patients recover and maintain quality of life after treatment.",
          "Research and Education: Advancing knowledge and understanding of head and neck tumors for improved future treatments.",
        ],
      },
      {
        name: "Corrective Jaw Surgery",
        subName: "(Reconstructing the Jaw to correct an Abnormal Bite)",
        mainDes: [
          "Corrective jaw surgery, also known as Orthognathic Surgery, is a surgical procedure performed to correct jaw abnormalities and improve facial balance.",
          "The surgery may involve repositioning the upper jaw (maxilla), lower jaw (mandible), or both to achieve optimal alignment and function.",
          
        ],
        note:"To Note: This surgery is done when braces alone cannot correct the malalignment of the teeth or when the jaw bones need correction.",
        img: CorrectiveJaw,
        head: {
          heading: "What is Corrective Jaw Surgery?",
          des: [
            "Corrective jaw surgery, also known as orthognathic surgery, is a surgical procedure performed to correct jaw abnormalities and improve facial balance.",
            "It is often recommended to address issues such as misaligned jaws, excessive overbite or underbite, facial asymmetry, and difficulty chewing, speaking, or breathing.",
            "The surgery may involve repositioning the upper jaw (maxilla), lower jaw (mandible), or both to achieve optimal alignment and function.",
          ],
        },
        procedure: [
          {
            heading: "Assessment and Planning",
            des: [
              "Consultation: Comprehensive evaluation by an oral and maxillofacial surgeon to assess jaw alignment and determine surgical needs.",
              "Orthodontic Preparation: Pre-surgical orthodontic treatment to align teeth and optimize bite alignment before surgery.",
            ],
          },
          {
            heading: "Surgical Procedure",
            des: [
              "Incisions: Surgical cuts are made inside the mouth (intraoral) or externally along the jawline (extraoral) to access the jaw bones.",
              "Bone Repositioning: The surgeon adjusts the position of the jaw bones to correct alignment and bite.",
              "Stabilization: Plates, screws, or wires may be used to stabilize the newly positioned jaw bones.",
              "Soft Tissue Adjustment: Repositioning of facial soft tissues to enhance facial aesthetics and function.",
            ],
          },
          {
            heading: "Recovery and Post-Surgical Care",
            des: [
              "Hospital Stay: Recovery typically begins with a short hospital stay for monitoring and pain management.",
              "Post-Operative Instructions: Detailed guidelines for managing swelling, pain, diet, oral hygiene, and activity restrictions.",
              "Follow-Up Visits: Regular follow-up appointments to monitor healing progress, adjust orthodontic appliances, and assess long-term outcomes.",
            ],
          },
        ],
        purpose: [
          "Correct Jaw Alignment: Surgery aims to achieve proper alignment of the jaws, improving bite function and facial balance.",
          "Enhance Facial Aesthetics: Addressing facial asymmetry and proportions for improved facial harmony and aesthetics.",
          "Improve Oral Function: Enhancing chewing, speaking, and breathing capabilities.",
          "Address Functional Issues: Correcting issues such as difficulty biting, chewing, or swallowing due to jaw misalignment.",
        ],
      },
      {
        name: "Chronic Facial Pain.",
        img: ChronicFacial,
        mainDes: [
          "Chronic facial pain is a prolonged pain experienced in the face, often linked to conditions such as temporomandibular joint disorders (TMJ), trigeminal neuralgia, or chronic sinusitis. It typically requires comprehensive medical evaluation and treatment to manage the symptoms effectively.",
        ],
      },
      {
        name: "Facial Nerve Problems.",
        img: FacialNerve,
        mainDes: [
          "Facial nerve problems, such as Bell's palsy or trigeminal neuralgia, can cause weakness, paralysis, or severe pain in the face.",
          "These conditions often require a combination of medical treatments, physical therapy, and sometimes surgery to alleviate symptoms and improve facial function.",
        ],
        head: {
          heading: "What are Facial Nerve Concerns (Facial Neuralgia)?",
          des: [
            "Facial nerve concerns, also known as facial neuralgia, refer to conditions that affect the facial nerve, causing pain, discomfort, or dysfunction.",
            "The facial nerve (seventh cranial nerve) controls muscles of facial expression, taste on the front two-thirds of the tongue, and tear production.",
            "Facial neuralgia can result from various causes, including nerve damage, compression, infections, or neurological conditions.",
          ],
        },
        procedure: [
          {
            heading: "Diagnosis and Evaluation",
            des: [
              "Clinical Examination: Assessment of facial muscle function, sensation, and reflexes.",
              "Imaging Studies: MRI or CT scans may be used to visualize the facial nerve and surrounding structures.",
              "Electrophysiological Tests: Nerve conduction studies and electromyography (EMG) to assess nerve function.",
            ],
          },
          {
            heading: "Treatment Approaches",
            des: [
              "Medications: Pain relievers, anti-inflammatory drugs, or anticonvulsants to manage pain and reduce nerve sensitivity.",
              "Physical Therapy: Techniques to improve muscle strength, coordination, and facial symmetry.",
              "Nerve Blocks: Injection of local anesthetics or steroids near the affected nerve to relieve pain.",
              "Surgical Interventions: Decompression surgery or microvascular decompression to relieve pressure on the facial nerve.",
            ],
          },
          {
            heading: "Management and Support",
            des: [
              "Pain Management: Strategies to alleviate pain, including medication adjustments and alternative therapies.",
              "Psychological Support: Counseling and support services to cope with emotional and psychological effects of facial neuralgia.",
              "Rehabilitation: Techniques to enhance facial muscle control and improve quality of life.",
            ],
          },
        ],
        purpose: [
          "Relieve Pain and Discomfort: Treatment aims to reduce facial pain and improve quality of life.",
          "Restore Facial Function: Enhancing facial nerve function and muscle control for normal facial expressions.",
          "Address Underlying Causes: Managing conditions or factors contributing to facial neuralgia.",
          "Supportive Care: Providing comprehensive care to address physical, emotional, and social aspects of facial nerve concerns.",
        ],
      },
      // {
      //   name: "Scar revision/ correction",
      //   img: ScarRevision,
      //   head: {
      //     heading: "What is Scar Revision/Correction?",
      //     des: [
      //       "Scar revision or correction refers to surgical or non-surgical procedures aimed at minimizing the appearance of scars and improving skin texture and appearance.",
      //       "It is typically performed to reduce the visibility of scars resulting from injury, surgery, burns, or skin conditions.",
      //       "Treatment options vary depending on the type, location, and severity of the scar.",
      //     ],
      //   },
      //   procedure: [
      //     {
      //       heading: "Evaluation and Planning",
      //       des: [
      //         "Consultation: Assessment of the scar by a dermatologist or plastic surgeon to determine the best approach for treatment.",
      //         "Skin Examination: Evaluation of scar characteristics, such as size, texture, color, and location.",
      //         "Discussion of Options: Explanation of treatment options, including surgical revision, laser therapy, or topical treatments.",
      //       ],
      //     },
      //     {
      //       heading: "Treatment Options",
      //       des: [
      //         "Surgical Revision: Excision of the scar tissue followed by closure with techniques to minimize scar formation.",
      //         "Laser Therapy: Use of laser devices to resurface the skin, reduce redness, and improve scar texture.",
      //         "Dermal Fillers: Injection of fillers to raise depressed scars and improve skin contour.",
      //         "Topical Treatments: Application of silicone gels, corticosteroid creams, or other medications to improve scar appearance.",
      //       ],
      //     },
      //     {
      //       heading: "Post-Treatment Care",
      //       des: [
      //         "Wound Care: Instructions for caring for the treated area to promote healing and minimize scar recurrence.",
      //         "Scar Management: Techniques such as massage or silicone sheeting to improve scar flexibility and appearance.",
      //         "Follow-Up Visits: Regular monitoring to assess scar healing and adjust treatment as needed.",
      //       ],
      //     },
      //   ],
      //   purpose: [
      //     "Improve Aesthetic Appearance: Minimize the visibility of scars for improved skin aesthetics and self-confidence.",
      //     "Restore Function: Address scars that restrict movement or cause discomfort.",
      //     "Enhance Skin Texture: Smooth out irregularities in skin texture caused by scars.",
      //     "Psychological Well-being: Boost self-esteem and reduce emotional distress associated with visible scars.",
      //   ],
      // },
      // {
      //   name: "Cosmetic surgery",
      //   img: CosmeticSurgery,
      //   head: {
      //     heading: "What is Cosmetic Surgery?",
      //     des: [
      //       "Cosmetic surgery, also known as aesthetic surgery, refers to surgical procedures aimed at enhancing the appearance of a person.",
      //       "It is typically elective and focuses on improving aesthetic appeal, symmetry, and proportion of facial and body features.",
      //       "Cosmetic surgery can address areas such as the face, neck, breasts, body contouring, and skin.",
      //     ],
      //   },
      //   procedure: [
      //     {
      //       heading: "Common Procedures",
      //       des: [
      //         "Facial Procedures: Facelift, rhinoplasty (nose surgery), eyelid surgery, brow lift, and chin augmentation.",
      //         "Breast Procedures: Breast augmentation, breast lift, breast reduction, and nipple/areola surgery.",
      //         "Body Contouring: Liposuction, tummy tuck (abdominoplasty), body lift, and thigh lift.",
      //         "Skin Rejuvenation: Laser resurfacing, chemical peels, dermal fillers, and Botox injections.",
      //       ],
      //     },
      //     {
      //       heading: "Treatment Process",
      //       des: [
      //         "Consultation: Initial meeting with a cosmetic surgeon to discuss goals, expectations, and medical history.",
      //         "Preparation: Pre-operative assessments, instructions, and planning for the procedure.",
      //         "Surgery: Procedure performed under anesthesia with careful attention to cosmetic goals and patient safety.",
      //         "Recovery: Post-operative care, monitoring, and instructions for optimal healing and results.",
      //         "Follow-Up: Monitoring progress, managing any concerns, and assessing long-term outcomes.",
      //       ],
      //     },
      //     {
      //       heading: "Benefits and Considerations",
      //       des: [
      //         "Enhanced Appearance: Improves symmetry, proportion, and aesthetic appeal.",
      //         "Boosted Confidence: Positive impact on self-esteem and body image.",
      //         "Personalized Approach: Tailored treatments to achieve individual goals.",
      //         "Risks and Recovery: Potential risks, recovery timeline, and realistic expectations.",
      //       ],
      //     },
      //   ],
      //   purpose: [
      //     "Enhance Aesthetic Appeal: Improve facial and body features to achieve desired appearance.",
      //     "Restore Youthful Appearance: Address signs of aging and enhance facial rejuvenation.",
      //     "Correct Defects: Correct congenital deformities, injuries, or physical abnormalities.",
      //     "Psychological Well-being: Boost self-confidence and improve overall quality of life.",
      //   ],
      // },
      // {
      //   name: "Dimploplasty (making a dimple on the cheek)",
      //   img: Dimploplasty,
      //   head: {
      //     heading: "What is Dimpleplasty (Cheek Dimple Surgery)?",
      //     des: [
      //       "Dimpleplasty, also known as cheek dimple surgery, is a cosmetic procedure aimed at creating dimples on the cheeks.",
      //       "Dimples are considered attractive facial features and are created by surgically forming a small depression in the cheek.",
      //       "The procedure is typically elective and involves minimal risk with the goal of enhancing facial aesthetics.",
      //     ],
      //   },
      //   procedure: [
      //     {
      //       heading: "Surgical Technique",
      //       des: [
      //         "Marking the Site: The surgeon marks the desired location on the cheek where the dimple will be created.",
      //         "Local Anesthesia: Local anesthesia is administered to numb the area and minimize discomfort.",
      //         "Creating the Dimple: A small incision is made on the inside of the cheek, and a suture is placed between the inner skin and muscle.",
      //         "Securing the Dimple: The suture creates a small depression in the cheek, simulating a natural dimple.",
      //         "Closing the Incision: The incision is closed with dissolvable stitches, and the procedure typically takes about 30 minutes.",
      //       ],
      //     },
      //     {
      //       heading: "Recovery and Results",
      //       des: [
      //         "Immediate Results: Dimples are visible immediately after surgery, though swelling and redness are normal and subside within a few days to weeks.",
      //         "Post-Operative Care: Instructions include gentle cleaning of the incision site, avoiding strenuous activities, and taking prescribed pain medications as needed.",
      //         "Long-Term Outcome: Dimpleplasty results are permanent, with the dimples becoming more defined as swelling resolves and tissues settle.",
      //       ],
      //     },
      //     {
      //       heading: "Considerations and Risks",
      //       des: [
      //         "Patient Selection: Candidates should have realistic expectations and understand the permanent nature of dimple creation.",
      //         "Potential Risks: Risks include infection, asymmetry, scarring, or changes in sensation, though these are rare.",
      //         "Alternative Options: Non-surgical dimple creation using temporary fillers may be considered for those unsure about permanent results.",
      //       ],
      //     },
      //   ],
      //   purpose: [
      //     "Enhance Facial Aesthetics: Create natural-looking dimples that enhance facial features and smile aesthetics.",
      //     "Boost Confidence: Improve self-esteem by achieving a desired facial appearance through elective cosmetic surgery.",
      //     "Personalized Approach: Tailor dimple creation to complement individual facial structure and symmetry.",
      //     "Safe and Effective: Achieve desired results with minimal risk and downtime under the care of a qualified cosmetic surgeon.",
      //   ],
      // },
      // {
      //   name: "Lobuloplasty (repair of ear lobe tear)",
      //   img: Lobuloplasty,
      //   head: {
      //     heading: "What is Lobuloplasty (Repair of Ear Lobe Tear)?",
      //     des: [
      //       "Lobuloplasty, also known as ear lobe repair surgery, is a cosmetic procedure aimed at correcting torn or stretched earlobes.",
      //       "Earlobe tears can result from trauma, heavy earrings, or prolonged wear, causing a split or elongation of the earlobe.",
      //       "The procedure involves surgical techniques to restore the natural shape and contour of the earlobe.",
      //     ],
      //   },
      //   procedure: [
      //     {
      //       heading: "Surgical Techniques",
      //       des: [
      //         "Evaluation: The surgeon assesses the extent and location of the earlobe tear to determine the best approach for repair.",
      //         "Anesthesia: Local anesthesia is typically used to numb the earlobe and minimize discomfort during the procedure.",
      //         "Repair Options: Depending on the tear, techniques may include direct closure with sutures, reconstruction with skin flaps, or tissue rearrangement.",
      //         "Cosmetic Considerations: Careful attention is paid to ensure symmetry, natural contour, and minimal scarring.",
      //       ],
      //     },
      //     {
      //       heading: "Recovery and Aftercare",
      //       des: [
      //         "Immediate Care: Post-surgery, the earlobe is bandaged to protect the repair and minimize swelling.",
      //         "Wound Healing: Instructions include gentle cleaning of the incision site, avoiding pressure or trauma to the earlobe, and applying prescribed ointments.",
      //         "Stitch Removal: Sutures are typically removed within 7-10 days, depending on the healing progress.",
      //         "Resuming Activities: Patients are advised to avoid strenuous activities or wearing earrings until the earlobe has fully healed.",
      //       ],
      //     },
      //     {
      //       heading: "Long-Term Results",
      //       des: [
      //         "Natural Appearance: The goal is to achieve a natural-looking earlobe with restored shape and symmetry.",
      //         "Scar Management: Techniques such as scar massage or silicone sheets may be recommended to improve the appearance of scars over time.",
      //         "Follow-Up Visits: Regular follow-up appointments allow the surgeon to monitor healing progress and address any concerns.",
      //       ],
      //     },
      //   ],
      //   purpose: [
      //     "Restore Earlobe Integrity: Correct torn or stretched earlobes to restore their natural shape and function.",
      //     "Improve Aesthetic Appearance: Enhance the appearance of the earlobes for improved symmetry and contour.",
      //     "Functional Restoration: Enable comfortable wearing of earrings and restore confidence in appearance.",
      //     "Personalized Care: Tailor surgical techniques to meet individual needs and achieve desired aesthetic outcomes.",
      //   ],
      // },
    ],
  },
  {
    logo: <TbToolsOff />,
    title: "Cosmetics",
    img: serviceFour,
    mainImg: serviceMainFour,
    mainDes: "Types of cosmetic surgery for the face",
    des: [
      "Cosmetic treatments designed to accentuate and enhance natural facial features through a non-surgical procedure. Some of the procedures are non- invasive procedures or minimally invasive procedure.",
      "Permanent make up(PMU) techniques of Microblading and micro pigmentation.Hair treatments are an equally important part of aesthetics, an issue that plagues the current system of modern lifestyle.",
    ],

    subTitle: [
      {
        name: "Botox",
        img: Botox,
        mainDes: [
          "Cosmetic treatment that temporarily reduce/ eliminate facial wrinkles and fine lines.",
          "Botox injections also have medical applications.",
        ],
        head: {
          heading: "What is Botox?",
          des: [
            "Botox is a cosmetic treatment that uses botulinum toxin to temporarily reduce or eliminate facial wrinkles and fine lines.",
            "It is a popular non-surgical procedure for smoothing wrinkles by paralyzing facial muscles temporarily.",
            "Botox injections are primarily used for cosmetic purposes but also have medical applications.",
          ],
        },
        procedure: [
          "Consultation: Initial meeting with a qualified provider to discuss treatment goals, medical history, and expectations.",
          "Injection Process: Botox is injected into targeted facial muscles using a fine needle to reduce muscle activity and smooth wrinkles.",
          "Duration: The procedure typically takes about 10-15 minutes, depending on the areas treated.",
          "Results: Initial effects may be visible within a few days, with full results appearing in about 1-2 weeks.",
          "Maintenance: Results last for 3-6 months, after which follow-up treatments are needed to maintain results.",
        ],
        purpose: [
          "Reduce Wrinkles: Smooths out wrinkles and fine lines caused by facial expressions, such as frown lines and crow's feet.",
          "Prevent New Wrinkles: By reducing muscle movement, Botox can help prevent the formation of new wrinkles.",
          "Medical Uses: Treats conditions like excessive sweating (hyperhidrosis), migraines, and muscle spasms.",
          "Enhance Appearance: Enhances facial aesthetics by providing a more youthful and relaxed appearance.",
        ],
      },
      {
        name: "Dermal fillers",
        img: DermalFillers,
        mainDes: [
          "Help diminish facial lines and restores volume and fullness of the face.",
        ],
        head: {
          heading: "What are Dermal Fillers?",
          des: [
            "Dermal fillers are injectable substances used to restore volume, smooth lines, and enhance facial contours.",
            "They are commonly used in cosmetic procedures to reduce wrinkles, plump lips, and improve facial symmetry.",
            "Various types of fillers are available, each with different compositions suited for specific areas of the face.",
          ],
        },
        procedure: [
          "Consultation: Initial assessment to discuss treatment goals, medical history, and options suitable for the desired outcome.",
          "Injection Process: Dermal fillers are injected into targeted areas using fine needles or cannulas to add volume or fill lines.",
          "Types of Fillers: Options include hyaluronic acid fillers (e.g., Juvederm, Restylane) and synthetic fillers (e.g., Radiesse, Sculptra).",
          "Duration: Results are visible immediately, with full effects appearing within a few days to weeks.",
          "Maintenance: Depending on the type of filler used, results can last from 6 months to 2 years, requiring periodic touch-ups.",
        ],
        purpose: [
          "Reduce Wrinkles and Lines: Softens facial creases, wrinkles, and folds, particularly around the mouth, eyes, and forehead.",
          "Restore Facial Volume: Adds volume to areas that have lost fullness due to aging or other factors.",
          "Enhance Facial Contours: Defines and enhances features such as cheeks, chin, and jawline for improved facial symmetry.",
          "Non-Surgical Lip Augmentation: Increases lip volume and reshapes lip contours for a fuller, more defined appearance.",
        ],
      },
      {
        name: "Wrinkle Treatment",
        img: WrinkleTreatment,
        mainDes: [
          "Wrinkle treatment encompasses a variety of methods aimed at reducing the appearance of wrinkles and fine lines on the skin. ",
        ],
      },
      // {
      //   name: "Chemical Peels",
      //   img: ChemicalPeels,
      //   head: {
      //     heading: "What are Chemical Peels?",
      //     des: [
      //       "Chemical peels are cosmetic treatments that involve applying a chemical solution to the skin to exfoliate and improve its texture.",
      //       "They are used to treat wrinkles, acne scars, uneven skin tone, and sun-damaged skin by removing damaged outer layers.",
      //       "Chemical peels vary in strength and can be tailored to different skin types and concerns.",
      //     ],
      //   },
      //   procedure: [
      //     "Consultation: Initial evaluation to assess skin type, concerns, and determine the most suitable peel strength and type.",
      //     "Preparation: Skin is cleansed thoroughly to remove oils and makeup before the chemical solution is applied.",
      //     "Application: The chemical solution is applied to the skin and left on for a specific period, depending on the peel's depth.",
      //     "Neutralization: Some peels may require neutralization to stop the chemical reaction and remove the solution.",
      //     "Recovery: Post-peel care instructions include moisturizing, avoiding sun exposure, and using sunscreen.",
      //   ],
      //   purpose: [
      //     "Improve Skin Texture: Smooths rough skin texture and reduces fine lines, wrinkles, and acne scars.",
      //     "Enhance Skin Tone: Evens out skin tone and diminishes pigmentation irregularities, such as sun spots or melasma.",
      //     "Stimulate Collagen Production: Promotes collagen growth for firmer, more youthful-looking skin over time.",
      //     "Customized Treatments: Tailored peels address specific concerns, from superficial peels for mild exfoliation to deep peels for significant skin rejuvenation.",
      //   ],
      // },
      // {
      //   name: "Microderma abrasion",
      //   img: MicrodermaAbrasion,
      //   head: {
      //     heading: "What are Chemical Peels?",
      //     des: [
      //       "Chemical peels are cosmetic treatments that involve applying a chemical solution to the skin to exfoliate and improve its texture.",
      //       "They are used to treat wrinkles, acne scars, uneven skin tone, and sun-damaged skin by removing damaged outer layers.",
      //       "Chemical peels vary in strength and can be tailored to different skin types and concerns.",
      //     ],
      //   },
      //   procedure: [
      //     "Consultation: Initial evaluation to assess skin type, concerns, and determine the most suitable peel strength and type.",
      //     "Preparation: Skin is cleansed thoroughly to remove oils and makeup before the chemical solution is applied.",
      //     "Application: The chemical solution is applied to the skin and left on for a specific period, depending on the peel's depth.",
      //     "Neutralization: Some peels may require neutralization to stop the chemical reaction and remove the solution.",
      //     "Recovery: Post-peel care instructions include moisturizing, avoiding sun exposure, and using sunscreen.",
      //   ],
      //   purpose: [
      //     "Improve Skin Texture: Smooths rough skin texture and reduces fine lines, wrinkles, and acne scars.",
      //     "Enhance Skin Tone: Evens out skin tone and diminishes pigmentation irregularities, such as sun spots or melasma.",
      //     "Stimulate Collagen Production: Promotes collagen growth for firmer, more youthful-looking skin over time.",
      //     "Customized Treatments: Tailored peels address specific concerns, from superficial peels for mild exfoliation to deep peels for significant skin rejuvenation.",
      //   ],
      // },
      {
        name: "Scar Revision/ Correction",
        img: ScarRemoval,
        mainDes: [
          "Minimizes the appearance of scars, improving skin texture and appearance.",
        ],
        head: {
          heading: "What is Scar Removal?",
          des: [
            "Scar removal refers to various treatments aimed at reducing the appearance of scars on the skin.",
            "Scars can result from injuries, surgeries, acne, or other skin conditions and may vary in size, shape, and texture.",
            "Different methods are used to improve scar appearance, depending on the type and severity of the scar.",
          ],
        },
        procedure: [
          {
            heading: "Types of Scar Removal Treatments",
            des: [
              "Topical Treatments: Creams, gels, or ointments containing ingredients like silicone or corticosteroids to improve scar appearance.",
              "Laser Therapy: Laser treatments target scar tissue to reduce redness, flatten raised scars, and improve skin texture.",
              "Microdermabrasion: Exfoliation technique to remove the outer layer of skin, promoting new skin growth and reducing scar visibility.",
              "Surgical Excision: Surgical removal of scar tissue followed by closure to minimize scar visibility.",
              "Injectable Treatments: Fillers or collagen-stimulating injections to raise depressed scars or improve skin texture.",
              "Chemical Peels: Chemical solutions applied to the skin to remove damaged outer layers and improve scar appearance.",
            ],
          },
          {
            heading: "Recovery and Results",
            des: [
              "Varies by Treatment: Recovery time depends on the type of treatment, ranging from minimal downtime for topical treatments to longer recovery for surgical procedures.",
              "Expected Results: Improvements in scar appearance, such as reduced size, smoother texture, and less noticeable coloration.",
              "Follow-Up Care: Post-treatment care instructions include sun protection, moisturizing, and avoiding activities that could irritate healing skin.",
            ],
          },
          {
            heading: "Considerations",
            des: [
              "Consultation: Initial assessment with a dermatologist or plastic surgeon to evaluate scar type, location, and treatment options.",
              "Patient Expectations: Realistic expectations regarding scar improvement and potential limitations of treatment.",
              "Multiple Treatments: Some scars may require multiple sessions or combination treatments for optimal results.",
            ],
          },
        ],
        purpose: [
          "Improve Scar Appearance: Minimize scar visibility and enhance skin texture for improved aesthetics.",
          "Boost Confidence: Address psychological impact by improving the appearance of visible scars.",
          "Personalized Treatment: Tailor treatments to individual scar characteristics and patient preferences.",
          "Functional Improvement: Enhance skin flexibility and reduce discomfort associated with scar tissue.",
        ],
      },
      {
        name: "Dimploplasty (making a dimple on the cheek)",
        img: Dimploplasty,
        mainDes: [
          "A cosmetic procedure aimed at creating dimples on the cheeks.",
          "The procedure is typically elective and involves minimal risk with the goal of enhancing facial aesthetics.",
        ],
        head: {
          heading: "What is Dimpleplasty (Cheek Dimple Surgery)?",
          des: [
            "Dimpleplasty, also known as cheek dimple surgery, is a cosmetic procedure aimed at creating dimples on the cheeks.",
            "Dimples are considered attractive facial features and are created by surgically forming a small depression in the cheek.",
            "The procedure is typically elective and involves minimal risk with the goal of enhancing facial aesthetics.",
          ],
        },
        procedure: [
          {
            heading: "Surgical Technique",
            des: [
              "Marking the Site: The surgeon marks the desired location on the cheek where the dimple will be created.",
              "Local Anesthesia: Local anesthesia is administered to numb the area and minimize discomfort.",
              "Creating the Dimple: A small incision is made on the inside of the cheek, and a suture is placed between the inner skin and muscle.",
              "Securing the Dimple: The suture creates a small depression in the cheek, simulating a natural dimple.",
              "Closing the Incision: The incision is closed with dissolvable stitches, and the procedure typically takes about 30 minutes.",
            ],
          },
          {
            heading: "Recovery and Results",
            des: [
              "Immediate Results: Dimples are visible immediately after surgery, though swelling and redness are normal and subside within a few days to weeks.",
              "Post-Operative Care: Instructions include gentle cleaning of the incision site, avoiding strenuous activities, and taking prescribed pain medications as needed.",
              "Long-Term Outcome: Dimpleplasty results are permanent, with the dimples becoming more defined as swelling resolves and tissues settle.",
            ],
          },
          {
            heading: "Considerations and Risks",
            des: [
              "Patient Selection: Candidates should have realistic expectations and understand the permanent nature of dimple creation.",
              "Potential Risks: Risks include infection, asymmetry, scarring, or changes in sensation, though these are rare.",
              "Alternative Options: Non-surgical dimple creation using temporary fillers may be considered for those unsure about permanent results.",
            ],
          },
        ],
        purpose: [
          "Enhance Facial Aesthetics: Create natural-looking dimples that enhance facial features and smile aesthetics.",
          "Boost Confidence: Improve self-esteem by achieving a desired facial appearance through elective cosmetic surgery.",
          "Personalized Approach: Tailor dimple creation to complement individual facial structure and symmetry.",
          "Safe and Effective: Achieve desired results with minimal risk and downtime under the care of a qualified cosmetic surgeon.",
        ],
      },
      {
        name: "Lobuloplasty",
        subName: "(repair of ear lobe tear) Blepharoplasty (eyelid surgery)",
        mainDes: [
          "Correcting torn or stretched earlobes.",
          "Earlobe tears can result from trauma, heavy earrings, or prolonged wear, causing a split or elongation of the earlobe.",
        ],
        img: Lobuloplasty,
        head: {
          heading: "What is Lobuloplasty (Repair of Ear Lobe Tear)?",
          des: [
            "Lobuloplasty, also known as ear lobe repair surgery, is a cosmetic procedure aimed at correcting torn or stretched earlobes.",
            "Earlobe tears can result from trauma, heavy earrings, or prolonged wear, causing a split or elongation of the earlobe.",
            "The procedure involves surgical techniques to restore the natural shape and contour of the earlobe.",
          ],
        },
        procedure: [
          {
            heading: "Surgical Techniques",
            des: [
              "Evaluation: The surgeon assesses the extent and location of the earlobe tear to determine the best approach for repair.",
              "Anesthesia: Local anesthesia is typically used to numb the earlobe and minimize discomfort during the procedure.",
              "Repair Options: Depending on the tear, techniques may include direct closure with sutures, reconstruction with skin flaps, or tissue rearrangement.",
              "Cosmetic Considerations: Careful attention is paid to ensure symmetry, natural contour, and minimal scarring.",
            ],
          },
          {
            heading: "Recovery and Aftercare",
            des: [
              "Immediate Care: Post-surgery, the earlobe is bandaged to protect the repair and minimize swelling.",
              "Wound Healing: Instructions include gentle cleaning of the incision site, avoiding pressure or trauma to the earlobe, and applying prescribed ointments.",
              "Stitch Removal: Sutures are typically removed within 7-10 days, depending on the healing progress.",
              "Resuming Activities: Patients are advised to avoid strenuous activities or wearing earrings until the earlobe has fully healed.",
            ],
          },
          {
            heading: "Long-Term Results",
            des: [
              "Natural Appearance: The goal is to achieve a natural-looking earlobe with restored shape and symmetry.",
              "Scar Management: Techniques such as scar massage or silicone sheets may be recommended to improve the appearance of scars over time.",
              "Follow-Up Visits: Regular follow-up appointments allow the surgeon to monitor healing progress and address any concerns.",
            ],
          },
        ],
        purpose: [
          "Restore Earlobe Integrity: Correct torn or stretched earlobes to restore their natural shape and function.",
          "Improve Aesthetic Appearance: Enhance the appearance of the earlobes for improved symmetry and contour.",
          "Functional Restoration: Enable comfortable wearing of earrings and restore confidence in appearance.",
          "Personalized Care: Tailor surgical techniques to meet individual needs and achieve desired aesthetic outcomes.",
        ],
      },
      {
        name: "Blepharoplasty (eyelid surgery)",
        img: Blepharoplasty,
        mainDes: [
          "Blepharoplasty (eyelid surgery)",
          "Anti-Aging Treatment, by removing excess skin from the upper eyelids",
        ],
      },
      {
        name: "Brow lift (forehead lift)",
        img: BrowLift,
        mainDes: [
          "The procedure involves raising the soft tissue and skin of the forehead and brow.",
        ],
      },
      {
        name: "Rhinoplasty (nose surgery)",
        img: Rhinoplasty,
        mainDes: ["Change the shape of the nose."],
      },
    ],
  },
  {
    logo: <TbToolsOff />,
    title: "Skin & Hair",
    img: serviceFour,
    mainImg: seserviceMainFive,
    mainDes: "",
    des: [
      "Cosmetic treatments designed to accentuate and enhance natural facial features through a non-surgical procedure. Some of the procedures are non- invasive procedures or minimally invasive procedure.",
      "Permanent make up(PMU) techniques of Microblading and micro pigmentation.Hair treatments are an equally important part of aesthetics, an issue that plagues the current system of modern lifestyle.",
    ],
    subHead: [
      {
        name: "Skin Rejuvenation and Resurfacing:",
        subTitle: [
          {
            name: "Chemical Peels",
            img: ChemicalPeels,
            mainDes: [
              "Various acid peels are used to remove damaged outer skin layers",
            ],
            head: {
              heading: "What are Chemical Peels?",
              des: [
                "Chemical peels are cosmetic treatments that involve applying a chemical solution to the skin to exfoliate and improve its texture.",
                "They are used to treat wrinkles, acne scars, uneven skin tone, and sun-damaged skin by removing damaged outer layers.",
                "Chemical peels vary in strength and can be tailored to different skin types and concerns.",
              ],
            },
            procedure: [
              "Consultation: Initial evaluation to assess skin type, concerns, and determine the most suitable peel strength and type.",
              "Preparation: Skin is cleansed thoroughly to remove oils and makeup before the chemical solution is applied.",
              "Application: The chemical solution is applied to the skin and left on for a specific period, depending on the peel's depth.",
              "Neutralization: Some peels may require neutralization to stop the chemical reaction and remove the solution.",
              "Recovery: Post-peel care instructions include moisturizing, avoiding sun exposure, and using sunscreen.",
            ],
            purpose: [
              "Improve Skin Texture: Smooths rough skin texture and reduces fine lines, wrinkles, and acne scars.",
              "Enhance Skin Tone: Evens out skin tone and diminishes pigmentation irregularities, such as sun spots or melasma.",
              "Stimulate Collagen Production: Promotes collagen growth for firmer, more youthful-looking skin over time.",
              "Customized Treatments: Tailored peels address specific concerns, from superficial peels for mild exfoliation to deep peels for significant skin rejuvenation.",
            ],
          },
          {
            name: "Microderma abrasion",
            img: MicrodermaAbrasion,
            mainDes: [
              "Gently sand the skin, and remove the thicker, uneven outer layer",
            ],
            head: {
              heading: "What are Chemical Peels?",
              des: [
                "Chemical peels are cosmetic treatments that involve applying a chemical solution to the skin to exfoliate and improve its texture.",
                "They are used to treat wrinkles, acne scars, uneven skin tone, and sun-damaged skin by removing damaged outer layers.",
                "Chemical peels vary in strength and can be tailored to different skin types and concerns.",
              ],
            },
            procedure: [
              "Consultation: Initial evaluation to assess skin type, concerns, and determine the most suitable peel strength and type.",
              "Preparation: Skin is cleansed thoroughly to remove oils and makeup before the chemical solution is applied.",
              "Application: The chemical solution is applied to the skin and left on for a specific period, depending on the peel's depth.",
              "Neutralization: Some peels may require neutralization to stop the chemical reaction and remove the solution.",
              "Recovery: Post-peel care instructions include moisturizing, avoiding sun exposure, and using sunscreen.",
            ],
            purpose: [
              "Improve Skin Texture: Smooths rough skin texture and reduces fine lines, wrinkles, and acne scars.",
              "Enhance Skin Tone: Evens out skin tone and diminishes pigmentation irregularities, such as sun spots or melasma.",
              "Stimulate Collagen Production: Promotes collagen growth for firmer, more youthful-looking skin over time.",
              "Customized Treatments: Tailored peels address specific concerns, from superficial peels for mild exfoliation to deep peels for significant skin rejuvenation.",
            ],
          },
          {
            name: "Hydra facial",
            img: HydraFacial,
            mainDes: [
              "Medical-grade hydradermabrasion device Gentle exfoliation and relaxing resurfacing of the skin.",
            ],
            head: {
              heading: "What is HydraFacial?",
              des: [
                "HydraFacial is a non-invasive facial treatment that combines cleansing, exfoliation, extraction, hydration, and antioxidant protection.",
                "It uses a patented device and specialized serums to cleanse and rejuvenate the skin, addressing various skin concerns.",
                "HydraFacial treatments are suitable for all skin types and offer immediate results with no downtime.",
              ],
            },
            procedure: [
              "Cleansing and Exfoliation: The skin is cleansed and exfoliated to remove dead skin cells and impurities.",
              "Acid Peel: A gentle peel solution is applied to loosen debris and prepare the skin for extraction.",
              "Extraction: Automated suction is used to remove debris from pores, reducing congestion and improving skin clarity.",
              "Hydration: Antioxidant-rich serums are infused into the skin to nourish and hydrate, promoting a healthy glow.",
              "Protection: The skin is protected with serums containing peptides and hyaluronic acid to enhance skin elasticity and firmness.",
            ],
            purpose: [
              "Deep Cleansing: Cleanses and exfoliates the skin, removing debris and impurities for a clearer complexion.",
              "Hydration Boost: Hydrates the skin with nourishing serums to improve moisture levels and skin texture.",
              "Enhance Skin Tone: Improves skin tone and vibrancy, reducing the appearance of fine lines, wrinkles, and hyperpigmentation.",
              "Immediate Results: Provides instant results with no downtime, making it ideal for quick skin rejuvenation.",
            ],
          },
          {
            name: "Microneedling of the face",
            mainDes: [
              "Fine needles create tiny punctures in the skin's surface, which stimulate the skin's natural healing.",
            ],
            img: Microneedling,
            head: {
              heading: "What is Microneedling?",
              des: [
                "Microneedling, also known as collagen induction therapy, is a minimally invasive cosmetic procedure.",
                "It involves using fine needles to create tiny punctures in the skin's surface.",
                "These micro-injuries stimulate the skin's natural healing process, promoting collagen and elastin production.",
              ],
            },
            procedure: [
              "Preparation: The skin is cleansed and numbing cream may be applied to minimize discomfort.",
              "Microneedling Device: A device with fine needles is passed over the skin, creating controlled micro-injuries.",
              "Serum Application: Hyaluronic acid or other serums may be applied to enhance penetration and hydration.",
              "Post-Treatment Care: Cooling masks or soothing creams may be applied to calm the skin post-procedure.",
              "Recovery: Mild redness and swelling are common initially, with full recovery typically within a few days to a week.",
            ],
            purpose: [
              "Collagen Production: Stimulates collagen and elastin production, improving skin texture and firmness.",
              "Scar Reduction: Helps reduce the appearance of acne scars, surgical scars, and stretch marks.",
              "Wrinkle Reduction: Minimizes fine lines and wrinkles, particularly around the eyes and mouth.",
              "Enhanced Absorption: Increases absorption of skincare products, maximizing their effectiveness.",
            ],
          },
          {
            name: "Face PRP",
            img: FacPRP,
            mainDes: [
              "Rejuvenating skincare treatment that uses the patient's own blood plasma.",
              "PRP contains growth factors that promote healing and stimulate collagen production in the skin.",
            ],
            head: {
              heading: "what is Platelet-Rich Plasma (PRP) Facial.?",
              des: [
                "A Platelet-Rich Plasma (PRP) facial is a rejuvenating skincare treatment that uses the patient's own blood plasma.",
                "PRP contains growth factors that promote healing and stimulate collagen production in the skin.",
                "The procedure involves extracting plasma from the patient's blood, processing it, and then applying it to the face.",
              ],
            },
            procedure: [
              "Blood Draw: A small amount of blood is drawn from the patient, typically from the arm.",
              "Centrifugation: The blood is processed in a centrifuge to separate the plasma rich in platelets from other blood components.",
              "Application: The PRP, now enriched with growth factors, is applied to the face using microneedling or injection techniques.",
              "Absorption: The growth factors in PRP penetrate the skin, stimulating collagen production and cellular repair.",
              "Post-Treatment Care: Recommendations for skincare and sun protection to optimize results.",
            ],
            purpose: [
              "Skin Rejuvenation: Improves skin texture, tone, and elasticity for a more youthful appearance.",
              "Collagen Boost: Stimulates collagen production, reducing fine lines, wrinkles, and acne scars.",
              "Natural Results: Uses the body's own healing factors for safe and effective rejuvenation.",
              "Minimal Downtime: Non-surgical procedure with minimal recovery time.",
            ],
          },
          {
            name: "Mole /Skin tag/wart removal",
            img: wartRemoval,
            mainDes: [
              "Removal methods vary based on the type, size, and location of the growth.",
            ],
            head: {
              heading: "what is Mole, Skin Tag, and Wart Removal.?",
              des: [
                "Mole, skin tag, and wart removal are common dermatological procedures to eliminate benign growths on the skin.",
                "These procedures are typically performed by dermatologists or trained healthcare professionals.",
                "Removal methods vary based on the type, size, and location of the growth.",
              ],
            },
            procedure: [
              {
                heading: "Types of Removal Techniques",
                des: [
                  "Excision: Surgical removal using a scalpel or surgical scissors for larger moles, skin tags, or warts.",
                  "Cryotherapy: Freezing the growth with liquid nitrogen to destroy the cells, often used for smaller moles, skin tags, and warts.",
                  "Electrosurgery: Using an electric current to burn and remove the growth, suitable for various sizes of moles, skin tags, and warts.",
                  "Laser Removal: Using laser technology to target and remove the growth, often preferred for precise and scar-free removal.",
                ],
              },
              {
                heading: "Post-Procedure Care",
                des: [
                  "Wound Care: Instructions for keeping the treated area clean and protected from infection.",
                  "Healing Time: Expected recovery period and any activities to avoid during healing.",
                  "Follow-Up: Schedule follow-up appointments to monitor healing and ensure complete removal.",
                ],
              },
            ],
            purpose: [
              "Cosmetic Improvement: Enhances skin appearance by removing unsightly or bothersome growths.",
              "Medical Concerns: Eliminates potential irritations or discomfort caused by moles, skin tags, or warts.",
              "Preventive Measures: Reduces the risk of irritation, infection, or changes in appearance.",
              "Peace of Mind: Provides reassurance and peace of mind regarding benign growths.",
            ],
          },
        ],
      },
      {
        name: "Lip and Eyebrows:",
        subTitle: [
          {
            name: "Lip neutralisation/ lightening (dark/smoker lips)",
            img: LipNeutralisation,
            mainDes: ["Reduces the darkness/ pigmentation of the lips."],
            head: {
              heading:
                "what is Lip Neutralisation/Lightening (Dark/Smoker's Lips)?",
              des: [
                "Lip neutralisation or lightening is a cosmetic procedure aimed at reducing the darkness or pigmentation of the lips.",
                "It is commonly sought by individuals with naturally dark lips or those affected by lip pigmentation due to smoking, sun exposure, or genetics.",
                "Various techniques and treatments can be used to achieve lighter and more even-toned lips.",
              ],
            },
            procedure: [
              {
                heading: "Topical Treatments",
                des: [
                  "Topical Creams: Prescription or over-the-counter creams containing ingredients like hydroquinone or kojic acid to lighten lip pigmentation.",
                  "Chemical Peels: Mild chemical peels applied to the lips to exfoliate and lighten darkened skin.",
                ],
              },
              {
                heading: "Professional Treatments",
                des: [
                  "Laser Therapy: Laser treatments target excess pigmentation in the lips, promoting lighter and more uniform skin tone.",
                  "Cryotherapy: Freezing techniques used to remove darkened tissue and stimulate lighter skin regeneration.",
                  "Microneedling: Microscopic needles create tiny channels in the lips, enhancing absorption of lightening agents and stimulating collagen production.",
                ],
              },
              {
                heading: "Home Remedies",
                des: [
                  "Natural Oils: Application of natural oils like almond oil or coconut oil known for their moisturizing and lightening properties.",
                  "Lip Scrubs: Exfoliation with sugar or coffee-based lip scrubs to remove dead skin cells and reveal lighter lips.",
                ],
              },
            ],
            purpose: [
              "Cosmetic Enhancement: Improves the appearance of the lips by reducing dark pigmentation and achieving a more even tone.",
              "Enhanced Confidence: Boosts self-esteem by providing a more aesthetically pleasing lip appearance.",
              "Personalized Approach: Tailored treatments based on the severity of pigmentation and individual preferences.",
              "Safe and Effective: Utilizes both professional and home-based methods to achieve desired results with minimal risk.",
            ],
          },
          {
            name: "Lip colour",
            img: LipColour,
            mainDes: [
              "Lip colour treatments aim to enhance or restore the natural colour and appearance of the lips.",
            ],
            head: {
              heading: "what is Lip Coloring ?",
              des: [
                "Lip coloring procedures involve enhancing or altering the natural color of the lips for aesthetic purposes.",
                "These procedures can range from temporary enhancements to permanent changes, depending on the technique used.",
                "Various methods exist to achieve desired lip colors, catering to different preferences and outcomes.",
              ],
            },
            procedure: [
              {
                heading: "Temporary Lip Coloring",
                des: [
                  "Lipstick: Application of cosmetic lipsticks in various shades to change lip color temporarily.",
                  "Lip Gloss: Use of lip glosses that add shine and color to the lips without permanent alteration.",
                ],
              },
              {
                heading: "Semi-Permanent Lip Coloring",
                des: [
                  "Lip Stains: Application of lip stains that provide longer-lasting color compared to traditional lipsticks.",
                  "Lip Tints: Tinting techniques that semi-permanently dye the lips for a natural-looking color.",
                ],
              },
              {
                heading: "Permanent Lip Coloring",
                des: [
                  "Lip Tattooing: Cosmetic tattooing (micropigmentation) to permanently implant pigment into the lips.",
                  "Lip Implants: Surgical procedures to implant silicone or other materials for permanent lip enhancement.",
                ],
              },
            ],
            purpose: [
              "Enhanced Aesthetics: Improves lip appearance by adding color and definition.",
              "Personal Expression: Allows individuals to express personal style and preferences.",
              "Long-lasting Results: Provides temporary to permanent solutions depending on the chosen method.",
              "Customization: Tailors lip color to complement facial features and skin tone.",
            ],
          },
          {
            name: "Microblading of eyebrows",
            img: Microblading,
            mainDes: [
              "A semi-permanent cosmetic procedure to enhance/reshape eyebrows.",
            ],
            head: {
              heading: "what is Microblading of Eyebrows?",
              des: [
                "Microblading is a semi-permanent cosmetic procedure used to enhance or reshape eyebrows.",
                "It involves implanting pigment into the skin using fine, hair-like strokes to create natural-looking eyebrows.",
                "The procedure is performed by trained professionals using specialized tools and techniques.",
              ],
            },
            procedure: [
              "Consultation: Discuss eyebrow shape, color preferences, and expected outcomes with a microblading specialist.",
              "Mapping: Outline the desired eyebrow shape and map out the placement of hair strokes.",
              "Pigment Selection: Choose a pigment that matches the natural eyebrow color and complements the client's skin tone.",
              "Microblading: Using a hand tool with microblades, create hair-like strokes by depositing pigment into the skin's surface.",
              "Aftercare: Provide instructions for post-treatment care to ensure proper healing and pigment retention.",
            ],
            purpose: [
              "Natural-Looking Brows: Enhance sparse or over-plucked eyebrows with natural-looking hair strokes.",
              "Enhanced Shape and Definition: Correct asymmetry or reshape eyebrows for a more defined appearance.",
              "Long-Lasting Results: Enjoy semi-permanent results that can last up to 1-3 years with proper care and maintenance.",
              "Time-Saving: Reduce daily makeup routines by waking up with defined eyebrows.",
            ],
          },
          // {
          //   name: "Chemical Peels",
          //   img: ChemicalPeels,
          //   head: {
          //     heading: "What are Chemical Peels?",
          //     des: [
          //       "Chemical peels are cosmetic treatments that involve applying a chemical solution to the skin to exfoliate and improve its texture.",
          //       "They are used to treat wrinkles, acne scars, uneven skin tone, and sun-damaged skin by removing damaged outer layers.",
          //       "Chemical peels vary in strength and can be tailored to different skin types and concerns.",
          //     ],
          //   },
          //   procedure: [
          //     "Consultation: Initial evaluation to assess skin type, concerns, and determine the most suitable peel strength and type.",
          //     "Preparation: Skin is cleansed thoroughly to remove oils and makeup before the chemical solution is applied.",
          //     "Application: The chemical solution is applied to the skin and left on for a specific period, depending on the peel's depth.",
          //     "Neutralization: Some peels may require neutralization to stop the chemical reaction and remove the solution.",
          //     "Recovery: Post-peel care instructions include moisturizing, avoiding sun exposure, and using sunscreen.",
          //   ],
          //   purpose: [
          //     "Improve Skin Texture: Smooths rough skin texture and reduces fine lines, wrinkles, and acne scars.",
          //     "Enhance Skin Tone: Evens out skin tone and diminishes pigmentation irregularities, such as sun spots or melasma.",
          //     "Stimulate Collagen Production: Promotes collagen growth for firmer, more youthful-looking skin over time.",
          //     "Customized Treatments: Tailored peels address specific concerns, from superficial peels for mild exfoliation to deep peels for significant skin rejuvenation.",
          //   ],
          // },
          // {
          //   name: "Microderma abrasion",
          //   img: MicrodermaAbrasion,
          //   head: {
          //     heading: "What are Chemical Peels?",
          //     des: [
          //       "Chemical peels are cosmetic treatments that involve applying a chemical solution to the skin to exfoliate and improve its texture.",
          //       "They are used to treat wrinkles, acne scars, uneven skin tone, and sun-damaged skin by removing damaged outer layers.",
          //       "Chemical peels vary in strength and can be tailored to different skin types and concerns.",
          //     ],
          //   },
          //   procedure: [
          //     "Consultation: Initial evaluation to assess skin type, concerns, and determine the most suitable peel strength and type.",
          //     "Preparation: Skin is cleansed thoroughly to remove oils and makeup before the chemical solution is applied.",
          //     "Application: The chemical solution is applied to the skin and left on for a specific period, depending on the peel's depth.",
          //     "Neutralization: Some peels may require neutralization to stop the chemical reaction and remove the solution.",
          //     "Recovery: Post-peel care instructions include moisturizing, avoiding sun exposure, and using sunscreen.",
          //   ],
          //   purpose: [
          //     "Improve Skin Texture: Smooths rough skin texture and reduces fine lines, wrinkles, and acne scars.",
          //     "Enhance Skin Tone: Evens out skin tone and diminishes pigmentation irregularities, such as sun spots or melasma.",
          //     "Stimulate Collagen Production: Promotes collagen growth for firmer, more youthful-looking skin over time.",
          //     "Customized Treatments: Tailored peels address specific concerns, from superficial peels for mild exfoliation to deep peels for significant skin rejuvenation.",
          //   ],
          // },
          // {
          //   name: "Hydra facial",
          //   img: HydraFacial,
          //   head: {
          //     heading: "What is HydraFacial?",
          //     des: [
          //       "HydraFacial is a non-invasive facial treatment that combines cleansing, exfoliation, extraction, hydration, and antioxidant protection.",
          //       "It uses a patented device and specialized serums to cleanse and rejuvenate the skin, addressing various skin concerns.",
          //       "HydraFacial treatments are suitable for all skin types and offer immediate results with no downtime.",
          //     ],
          //   },
          //   procedure: [
          //     "Cleansing and Exfoliation: The skin is cleansed and exfoliated to remove dead skin cells and impurities.",
          //     "Acid Peel: A gentle peel solution is applied to loosen debris and prepare the skin for extraction.",
          //     "Extraction: Automated suction is used to remove debris from pores, reducing congestion and improving skin clarity.",
          //     "Hydration: Antioxidant-rich serums are infused into the skin to nourish and hydrate, promoting a healthy glow.",
          //     "Protection: The skin is protected with serums containing peptides and hyaluronic acid to enhance skin elasticity and firmness.",
          //   ],
          //   purpose: [
          //     "Deep Cleansing: Cleanses and exfoliates the skin, removing debris and impurities for a clearer complexion.",
          //     "Hydration Boost: Hydrates the skin with nourishing serums to improve moisture levels and skin texture.",
          //     "Enhance Skin Tone: Improves skin tone and vibrancy, reducing the appearance of fine lines, wrinkles, and hyperpigmentation.",
          //     "Immediate Results: Provides instant results with no downtime, making it ideal for quick skin rejuvenation.",
          //   ],
          // },
          // {
          //   name: "Microneedling of the face",
          //   img: Microneedling,
          //   head: {
          //     heading: "What is Microneedling?",
          //     des: [
          //       "Microneedling, also known as collagen induction therapy, is a minimally invasive cosmetic procedure.",
          //       "It involves using fine needles to create tiny punctures in the skin's surface.",
          //       "These micro-injuries stimulate the skin's natural healing process, promoting collagen and elastin production.",
          //     ],
          //   },
          //   procedure: [
          //     "Preparation: The skin is cleansed and numbing cream may be applied to minimize discomfort.",
          //     "Microneedling Device: A device with fine needles is passed over the skin, creating controlled micro-injuries.",
          //     "Serum Application: Hyaluronic acid or other serums may be applied to enhance penetration and hydration.",
          //     "Post-Treatment Care: Cooling masks or soothing creams may be applied to calm the skin post-procedure.",
          //     "Recovery: Mild redness and swelling are common initially, with full recovery typically within a few days to a week.",
          //   ],
          //   purpose: [
          //     "Collagen Production: Stimulates collagen and elastin production, improving skin texture and firmness.",
          //     "Scar Reduction: Helps reduce the appearance of acne scars, surgical scars, and stretch marks.",
          //     "Wrinkle Reduction: Minimizes fine lines and wrinkles, particularly around the eyes and mouth.",
          //     "Enhanced Absorption: Increases absorption of skincare products, maximizing their effectiveness.",
          //   ],
          // },
          // {
          //   name: "Face PRP",
          //   img: FacPRP,
          //   head: {
          //     heading: "what is Platelet-Rich Plasma (PRP) Facial.?",
          //     des: [
          //       "A Platelet-Rich Plasma (PRP) facial is a rejuvenating skincare treatment that uses the patient's own blood plasma.",
          //       "PRP contains growth factors that promote healing and stimulate collagen production in the skin.",
          //       "The procedure involves extracting plasma from the patient's blood, processing it, and then applying it to the face.",
          //     ],
          //   },
          //   procedure: [
          //     "Blood Draw: A small amount of blood is drawn from the patient, typically from the arm.",
          //     "Centrifugation: The blood is processed in a centrifuge to separate the plasma rich in platelets from other blood components.",
          //     "Application: The PRP, now enriched with growth factors, is applied to the face using microneedling or injection techniques.",
          //     "Absorption: The growth factors in PRP penetrate the skin, stimulating collagen production and cellular repair.",
          //     "Post-Treatment Care: Recommendations for skincare and sun protection to optimize results.",
          //   ],
          //   purpose: [
          //     "Skin Rejuvenation: Improves skin texture, tone, and elasticity for a more youthful appearance.",
          //     "Collagen Boost: Stimulates collagen production, reducing fine lines, wrinkles, and acne scars.",
          //     "Natural Results: Uses the body's own healing factors for safe and effective rejuvenation.",
          //     "Minimal Downtime: Non-surgical procedure with minimal recovery time.",
          //   ],
          // },
          // {
          //   name: "Mole /Skin tag/wart removal",
          //   img: wartRemoval,
          //   head: {
          //     heading: "what is Mole, Skin Tag, and Wart Removal.?",
          //     des: [
          //       "Mole, skin tag, and wart removal are common dermatological procedures to eliminate benign growths on the skin.",
          //       "These procedures are typically performed by dermatologists or trained healthcare professionals.",
          //       "Removal methods vary based on the type, size, and location of the growth.",
          //     ],
          //   },
          //   procedure: [
          //     {
          //       heading: "Types of Removal Techniques",
          //       des: [
          //         "Excision: Surgical removal using a scalpel or surgical scissors for larger moles, skin tags, or warts.",
          //         "Cryotherapy: Freezing the growth with liquid nitrogen to destroy the cells, often used for smaller moles, skin tags, and warts.",
          //         "Electrosurgery: Using an electric current to burn and remove the growth, suitable for various sizes of moles, skin tags, and warts.",
          //         "Laser Removal: Using laser technology to target and remove the growth, often preferred for precise and scar-free removal.",
          //       ],
          //     },
          //     {
          //       heading: "Post-Procedure Care",
          //       des: [
          //         "Wound Care: Instructions for keeping the treated area clean and protected from infection.",
          //         "Healing Time: Expected recovery period and any activities to avoid during healing.",
          //         "Follow-Up: Schedule follow-up appointments to monitor healing and ensure complete removal.",
          //       ],
          //     },
          //   ],
          //   purpose: [
          //     "Cosmetic Improvement: Enhances skin appearance by removing unsightly or bothersome growths.",
          //     "Medical Concerns: Eliminates potential irritations or discomfort caused by moles, skin tags, or warts.",
          //     "Preventive Measures: Reduces the risk of irritation, infection, or changes in appearance.",
          //     "Peace of Mind: Provides reassurance and peace of mind regarding benign growths.",
          //   ],
          // },
        ],
      },
      {
        name: "Hair:",
        subTitle: [
          {
            name: "Platelet-Rich Plasma (PRP) injections for Hair Growth.",
            img: HairPRP,
            mainDes: [
              "The patient's own blood plasma is injected.",
              "PRP contains growth factors that promote hair growth and improves hair density.",
            ],
            head: {
              heading:
                "what is Platelet-Rich Plasma (PRP) Treatment for Hair ?",
              des: [
                "Platelet-Rich Plasma (PRP) treatment for hair is a non-surgical procedure that utilizes the patient's own blood plasma.",
                "PRP contains growth factors that promote hair growth and improve hair density.",
                "The procedure involves extracting PRP from the patient's blood and injecting it into the scalp.",
              ],
            },
            procedure: [
              "Blood Draw: A small amount of blood is drawn from the patient, usually from the arm.",
              "Centrifugation: The blood is processed in a centrifuge to separate the plasma rich in platelets from other blood components.",
              "Injection: The PRP, enriched with growth factors, is injected into the scalp at the level of the hair follicles.",
              "Stimulation: Growth factors in PRP stimulate hair follicles, promote hair growth, and prolong the growth phase of hair.",
              "Post-Treatment Care: Recommendations for scalp care and follow-up treatments to maintain results.",
            ],
            purpose: [
              "Hair Regrowth: Stimulates dormant hair follicles to promote new hair growth.",
              "Hair Thickening: Increases hair thickness and density for fuller-looking hair.",
              "Natural Approach: Utilizes the body's own healing factors to enhance hair growth.",
              "Minimal Downtime: Non-invasive procedure with minimal recovery time.",
            ],
          },
        ],
      },
    ],

    // subTitle: [
    //   {
    //     name: "Chemical Peels",
    //     img: ChemicalPeels,
    //     head: {
    //       heading: "What are Chemical Peels?",
    //       des: [
    //         "Chemical peels are cosmetic treatments that involve applying a chemical solution to the skin to exfoliate and improve its texture.",
    //         "They are used to treat wrinkles, acne scars, uneven skin tone, and sun-damaged skin by removing damaged outer layers.",
    //         "Chemical peels vary in strength and can be tailored to different skin types and concerns.",
    //       ],
    //     },
    //     procedure: [
    //       "Consultation: Initial evaluation to assess skin type, concerns, and determine the most suitable peel strength and type.",
    //       "Preparation: Skin is cleansed thoroughly to remove oils and makeup before the chemical solution is applied.",
    //       "Application: The chemical solution is applied to the skin and left on for a specific period, depending on the peel's depth.",
    //       "Neutralization: Some peels may require neutralization to stop the chemical reaction and remove the solution.",
    //       "Recovery: Post-peel care instructions include moisturizing, avoiding sun exposure, and using sunscreen.",
    //     ],
    //     purpose: [
    //       "Improve Skin Texture: Smooths rough skin texture and reduces fine lines, wrinkles, and acne scars.",
    //       "Enhance Skin Tone: Evens out skin tone and diminishes pigmentation irregularities, such as sun spots or melasma.",
    //       "Stimulate Collagen Production: Promotes collagen growth for firmer, more youthful-looking skin over time.",
    //       "Customized Treatments: Tailored peels address specific concerns, from superficial peels for mild exfoliation to deep peels for significant skin rejuvenation.",
    //     ],
    //   },
    //   {
    //     name: "Microderma abrasion",
    //     img: MicrodermaAbrasion,
    //     head: {
    //       heading: "What are Chemical Peels?",
    //       des: [
    //         "Chemical peels are cosmetic treatments that involve applying a chemical solution to the skin to exfoliate and improve its texture.",
    //         "They are used to treat wrinkles, acne scars, uneven skin tone, and sun-damaged skin by removing damaged outer layers.",
    //         "Chemical peels vary in strength and can be tailored to different skin types and concerns.",
    //       ],
    //     },
    //     procedure: [
    //       "Consultation: Initial evaluation to assess skin type, concerns, and determine the most suitable peel strength and type.",
    //       "Preparation: Skin is cleansed thoroughly to remove oils and makeup before the chemical solution is applied.",
    //       "Application: The chemical solution is applied to the skin and left on for a specific period, depending on the peel's depth.",
    //       "Neutralization: Some peels may require neutralization to stop the chemical reaction and remove the solution.",
    //       "Recovery: Post-peel care instructions include moisturizing, avoiding sun exposure, and using sunscreen.",
    //     ],
    //     purpose: [
    //       "Improve Skin Texture: Smooths rough skin texture and reduces fine lines, wrinkles, and acne scars.",
    //       "Enhance Skin Tone: Evens out skin tone and diminishes pigmentation irregularities, such as sun spots or melasma.",
    //       "Stimulate Collagen Production: Promotes collagen growth for firmer, more youthful-looking skin over time.",
    //       "Customized Treatments: Tailored peels address specific concerns, from superficial peels for mild exfoliation to deep peels for significant skin rejuvenation.",
    //     ],
    //   },
    //   {
    //     name: "Hydra facial",
    //     img: HydraFacial,
    //     head: {
    //       heading: "What is HydraFacial?",
    //       des: [
    //         "HydraFacial is a non-invasive facial treatment that combines cleansing, exfoliation, extraction, hydration, and antioxidant protection.",
    //         "It uses a patented device and specialized serums to cleanse and rejuvenate the skin, addressing various skin concerns.",
    //         "HydraFacial treatments are suitable for all skin types and offer immediate results with no downtime.",
    //       ],
    //     },
    //     procedure: [
    //       "Cleansing and Exfoliation: The skin is cleansed and exfoliated to remove dead skin cells and impurities.",
    //       "Acid Peel: A gentle peel solution is applied to loosen debris and prepare the skin for extraction.",
    //       "Extraction: Automated suction is used to remove debris from pores, reducing congestion and improving skin clarity.",
    //       "Hydration: Antioxidant-rich serums are infused into the skin to nourish and hydrate, promoting a healthy glow.",
    //       "Protection: The skin is protected with serums containing peptides and hyaluronic acid to enhance skin elasticity and firmness.",
    //     ],
    //     purpose: [
    //       "Deep Cleansing: Cleanses and exfoliates the skin, removing debris and impurities for a clearer complexion.",
    //       "Hydration Boost: Hydrates the skin with nourishing serums to improve moisture levels and skin texture.",
    //       "Enhance Skin Tone: Improves skin tone and vibrancy, reducing the appearance of fine lines, wrinkles, and hyperpigmentation.",
    //       "Immediate Results: Provides instant results with no downtime, making it ideal for quick skin rejuvenation.",
    //     ],
    //   },
    //   {
    //     name: "Microneedling of the face",
    //     img: Microneedling,
    //     head: {
    //       heading: "What is Microneedling?",
    //       des: [
    //         "Microneedling, also known as collagen induction therapy, is a minimally invasive cosmetic procedure.",
    //         "It involves using fine needles to create tiny punctures in the skin's surface.",
    //         "These micro-injuries stimulate the skin's natural healing process, promoting collagen and elastin production.",
    //       ],
    //     },
    //     procedure: [
    //       "Preparation: The skin is cleansed and numbing cream may be applied to minimize discomfort.",
    //       "Microneedling Device: A device with fine needles is passed over the skin, creating controlled micro-injuries.",
    //       "Serum Application: Hyaluronic acid or other serums may be applied to enhance penetration and hydration.",
    //       "Post-Treatment Care: Cooling masks or soothing creams may be applied to calm the skin post-procedure.",
    //       "Recovery: Mild redness and swelling are common initially, with full recovery typically within a few days to a week.",
    //     ],
    //     purpose: [
    //       "Collagen Production: Stimulates collagen and elastin production, improving skin texture and firmness.",
    //       "Scar Reduction: Helps reduce the appearance of acne scars, surgical scars, and stretch marks.",
    //       "Wrinkle Reduction: Minimizes fine lines and wrinkles, particularly around the eyes and mouth.",
    //       "Enhanced Absorption: Increases absorption of skincare products, maximizing their effectiveness.",
    //     ],
    //   },
    //   {
    //     name: "Face PRP",
    //     img: FacPRP,
    //     head: {
    //       heading: "what is Platelet-Rich Plasma (PRP) Facial.?",
    //       des: [
    //         "A Platelet-Rich Plasma (PRP) facial is a rejuvenating skincare treatment that uses the patient's own blood plasma.",
    //         "PRP contains growth factors that promote healing and stimulate collagen production in the skin.",
    //         "The procedure involves extracting plasma from the patient's blood, processing it, and then applying it to the face.",
    //       ],
    //     },
    //     procedure: [
    //       "Blood Draw: A small amount of blood is drawn from the patient, typically from the arm.",
    //       "Centrifugation: The blood is processed in a centrifuge to separate the plasma rich in platelets from other blood components.",
    //       "Application: The PRP, now enriched with growth factors, is applied to the face using microneedling or injection techniques.",
    //       "Absorption: The growth factors in PRP penetrate the skin, stimulating collagen production and cellular repair.",
    //       "Post-Treatment Care: Recommendations for skincare and sun protection to optimize results.",
    //     ],
    //     purpose: [
    //       "Skin Rejuvenation: Improves skin texture, tone, and elasticity for a more youthful appearance.",
    //       "Collagen Boost: Stimulates collagen production, reducing fine lines, wrinkles, and acne scars.",
    //       "Natural Results: Uses the body's own healing factors for safe and effective rejuvenation.",
    //       "Minimal Downtime: Non-surgical procedure with minimal recovery time.",
    //     ],
    //   },
    //   {
    //     name: "Mole /Skin tag/wart removal",
    //     img: wartRemoval,
    //     head: {
    //       heading: "what is Mole, Skin Tag, and Wart Removal.?",
    //       des: [
    //         "Mole, skin tag, and wart removal are common dermatological procedures to eliminate benign growths on the skin.",
    //         "These procedures are typically performed by dermatologists or trained healthcare professionals.",
    //         "Removal methods vary based on the type, size, and location of the growth.",
    //       ],
    //     },
    //     procedure: [
    //       {
    //         heading: "Types of Removal Techniques",
    //         des: [
    //           "Excision: Surgical removal using a scalpel or surgical scissors for larger moles, skin tags, or warts.",
    //           "Cryotherapy: Freezing the growth with liquid nitrogen to destroy the cells, often used for smaller moles, skin tags, and warts.",
    //           "Electrosurgery: Using an electric current to burn and remove the growth, suitable for various sizes of moles, skin tags, and warts.",
    //           "Laser Removal: Using laser technology to target and remove the growth, often preferred for precise and scar-free removal.",
    //         ],
    //       },
    //       {
    //         heading: "Post-Procedure Care",
    //         des: [
    //           "Wound Care: Instructions for keeping the treated area clean and protected from infection.",
    //           "Healing Time: Expected recovery period and any activities to avoid during healing.",
    //           "Follow-Up: Schedule follow-up appointments to monitor healing and ensure complete removal.",
    //         ],
    //       },
    //     ],
    //     purpose: [
    //       "Cosmetic Improvement: Enhances skin appearance by removing unsightly or bothersome growths.",
    //       "Medical Concerns: Eliminates potential irritations or discomfort caused by moles, skin tags, or warts.",
    //       "Preventive Measures: Reduces the risk of irritation, infection, or changes in appearance.",
    //       "Peace of Mind: Provides reassurance and peace of mind regarding benign growths.",
    //     ],
    //   },

    //   {
    //     name: "Scar removal",
    //     img: ScarRemoval,
    //     head: {
    //       heading: "What is Scar Removal?",
    //       des: [
    //         "Scar removal refers to various treatments aimed at reducing the appearance of scars on the skin.",
    //         "Scars can result from injuries, surgeries, acne, or other skin conditions and may vary in size, shape, and texture.",
    //         "Different methods are used to improve scar appearance, depending on the type and severity of the scar.",
    //       ],
    //     },
    //     procedure: [
    //       {
    //         heading: "Types of Scar Removal Treatments",
    //         des: [
    //           "Topical Treatments: Creams, gels, or ointments containing ingredients like silicone or corticosteroids to improve scar appearance.",
    //           "Laser Therapy: Laser treatments target scar tissue to reduce redness, flatten raised scars, and improve skin texture.",
    //           "Microdermabrasion: Exfoliation technique to remove the outer layer of skin, promoting new skin growth and reducing scar visibility.",
    //           "Surgical Excision: Surgical removal of scar tissue followed by closure to minimize scar visibility.",
    //           "Injectable Treatments: Fillers or collagen-stimulating injections to raise depressed scars or improve skin texture.",
    //           "Chemical Peels: Chemical solutions applied to the skin to remove damaged outer layers and improve scar appearance.",
    //         ],
    //       },
    //       {
    //         heading: "Recovery and Results",
    //         des: [
    //           "Varies by Treatment: Recovery time depends on the type of treatment, ranging from minimal downtime for topical treatments to longer recovery for surgical procedures.",
    //           "Expected Results: Improvements in scar appearance, such as reduced size, smoother texture, and less noticeable coloration.",
    //           "Follow-Up Care: Post-treatment care instructions include sun protection, moisturizing, and avoiding activities that could irritate healing skin.",
    //         ],
    //       },
    //       {
    //         heading: "Considerations",
    //         des: [
    //           "Consultation: Initial assessment with a dermatologist or plastic surgeon to evaluate scar type, location, and treatment options.",
    //           "Patient Expectations: Realistic expectations regarding scar improvement and potential limitations of treatment.",
    //           "Multiple Treatments: Some scars may require multiple sessions or combination treatments for optimal results.",
    //         ],
    //       },
    //     ],
    //     purpose: [
    //       "Improve Scar Appearance: Minimize scar visibility and enhance skin texture for improved aesthetics.",
    //       "Boost Confidence: Address psychological impact by improving the appearance of visible scars.",
    //       "Personalized Treatment: Tailor treatments to individual scar characteristics and patient preferences.",
    //       "Functional Improvement: Enhance skin flexibility and reduce discomfort associated with scar tissue.",
    //     ],
    //   },
    //   {
    //     name: "Skin rejuvenation",
    //     img: SkinRejuvenation,
    //     head: {
    //       heading: "What is Skin Rejuvenation?",
    //       des: [
    //         "Skin rejuvenation refers to treatments and procedures aimed at improving the appearance and health of the skin.",
    //         "These treatments address various skin concerns, such as wrinkles, fine lines, age spots, uneven tone, and texture.",
    //         "Different methods are used to stimulate collagen production, remove damaged skin layers, and enhance overall skin health.",
    //       ],
    //     },
    //     procedure: [
    //       {
    //         heading: "Types of Skin Rejuvenation Treatments",
    //         des: [
    //           "Chemical Peels: Chemical solutions applied to the skin to exfoliate and improve texture, targeting wrinkles and pigmentation.",
    //           "Microdermabrasion: Mechanical exfoliation to remove dead skin cells and stimulate cell turnover for smoother skin.",
    //           "Laser Resurfacing: Laser treatments to remove damaged skin layers, stimulate collagen production, and reduce wrinkles.",
    //           "Microneedling: Fine needles create microscopic injuries to promote collagen and elastin production, improving skin texture.",
    //           "Radiofrequency Therapy: Energy waves heat the deeper layers of skin to stimulate collagen and tighten sagging skin.",
    //         ],
    //       },
    //       {
    //         heading: "Benefits of Skin Rejuvenation",
    //         des: [
    //           "Improves Skin Texture: Smoothes rough skin texture and reduces the appearance of fine lines and wrinkles.",
    //           "Evens Skin Tone: Reduces pigmentation irregularities, such as age spots, sun spots, and melasma.",
    //           "Stimulates Collagen Production: Enhances skin elasticity and firmness by boosting collagen and elastin fibers.",
    //           "Promotes Skin Health: Enhances overall skin health, including hydration, brightness, and resilience.",
    //         ],
    //       },
    //       {
    //         heading: "Considerations",
    //         des: [
    //           "Consultation: Initial assessment with a dermatologist or skincare specialist to determine the best treatment based on skin type and concerns.",
    //           "Treatment Plan: Customized approach tailored to individual needs, combining different treatments for optimal results.",
    //           "Maintenance: Follow-up treatments and skincare regimen to maintain results and prolong benefits.",
    //         ],
    //       },
    //     ],
    //     purpose: [
    //       "Enhance Skin Appearance: Improves skin texture, tone, and radiance for a more youthful and healthy appearance.",
    //       "Reduce Signs of Aging: Minimizes wrinkles, fine lines, and age spots associated with aging and sun damage.",
    //       "Boost Self-Confidence: Enhances skin quality and appearance, boosting self-esteem and well-being.",
    //       "Personalized Care: Tailored treatments address specific skin concerns and achieve desired aesthetic goals.",
    //     ],
    //   },
    //   {
    //     name: "Skin polish",
    //     img: SkinPolish,
    //     head: {
    //       heading: "What is Skin Polishing?",
    //       des: [
    //         "Skin polishing is a cosmetic treatment designed to exfoliate, hydrate, and rejuvenate the skin.",
    //         "It involves the use of gentle exfoliants and nourishing ingredients to remove dead skin cells, unclog pores, and improve skin texture.",
    //         "Skin polishing treatments can be performed in a spa or dermatologist's office, offering immediate benefits for smoother, brighter skin.",
    //       ],
    //     },
    //     procedure: [
    //       "Cleansing: The skin is cleansed thoroughly to remove dirt, oil, and makeup residues.",
    //       "Exfoliation: Gentle exfoliants or scrubs are applied to the skin to remove dead skin cells and unclog pores.",
    //       "Massage: A relaxing massage may be performed to improve circulation and lymphatic drainage.",
    //       "Mask Application: Nourishing masks or serums enriched with vitamins and antioxidants are applied to hydrate and rejuvenate the skin.",
    //       "Moisturizing: Moisturizers or sunscreens are applied to protect and hydrate the skin after treatment.",
    //     ],
    //     purpose: [
    //       "Exfoliate and Renew: Removes dead skin cells and impurities, revealing smoother and more radiant skin.",
    //       "Improve Skin Texture: Softens rough skin texture and reduces the appearance of fine lines and wrinkles.",
    //       "Enhance Skin Hydration: Hydrates and nourishes the skin, improving moisture levels and overall skin health.",
    //       "Brighten Complexion: Evens out skin tone and promotes a brighter, more youthful complexion.",
    //     ],
    //   },

    //   {
    //     name: "Medifacial",
    //     img: Medifacial,
    //     head: {
    //       heading: "What is a Medifacial?",
    //       des: [
    //         "A Medifacial is a medical-grade facial treatment that combines advanced skincare techniques and products.",
    //         "It is designed to address specific skin concerns using clinically-proven ingredients and procedures.",
    //         "Medifacials are typically performed under the supervision of a dermatologist or trained skincare professional.",
    //       ],
    //     },
    //     procedure: [
    //       "Consultation: Initial assessment to evaluate skin type, concerns, and determine the most suitable treatment plan.",
    //       "Cleansing: Thorough cleansing of the skin to remove makeup, dirt, and oil.",
    //       "Exfoliation: Gentle exfoliation to remove dead skin cells and prepare the skin for treatment.",
    //       "Treatment Application: Application of medical-grade serums, peels, or masks tailored to address specific skin issues.",
    //       "Massage: Relaxing massage techniques may be used to enhance circulation and product absorption.",
    //       "Protection: Application of SPF or protective creams to safeguard the skin post-treatment.",
    //     ],
    //     purpose: [
    //       "Targeted Skin Improvement: Addresses specific skin concerns such as acne, hyperpigmentation, fine lines, or dehydration.",
    //       "Enhance Skin Health: Improves skin texture, tone, and overall appearance using clinically-backed ingredients.",
    //       "Customized Approach: Tailored treatments based on individual skin needs and desired outcomes.",
    //       "Professional Supervision: Ensures treatments are performed safely and effectively under medical guidance.",
    //     ],
    //   },

    //   {
    //     name: "Mole /Skin tag/wart removal",
    //     img: wartRemoval,
    //     head: {
    //       heading: "what is Mole, Skin Tag, and Wart Removal.?",
    //       des: [
    //         "Mole, skin tag, and wart removal are common dermatological procedures to eliminate benign growths on the skin.",
    //         "These procedures are typically performed by dermatologists or trained healthcare professionals.",
    //         "Removal methods vary based on the type, size, and location of the growth.",
    //       ],
    //     },
    //     procedure: [
    //       {
    //         heading: "Types of Removal Techniques",
    //         des: [
    //           "Excision: Surgical removal using a scalpel or surgical scissors for larger moles, skin tags, or warts.",
    //           "Cryotherapy: Freezing the growth with liquid nitrogen to destroy the cells, often used for smaller moles, skin tags, and warts.",
    //           "Electrosurgery: Using an electric current to burn and remove the growth, suitable for various sizes of moles, skin tags, and warts.",
    //           "Laser Removal: Using laser technology to target and remove the growth, often preferred for precise and scar-free removal.",
    //         ],
    //       },
    //       {
    //         heading: "Post-Procedure Care",
    //         des: [
    //           "Wound Care: Instructions for keeping the treated area clean and protected from infection.",
    //           "Healing Time: Expected recovery period and any activities to avoid during healing.",
    //           "Follow-Up: Schedule follow-up appointments to monitor healing and ensure complete removal.",
    //         ],
    //       },
    //     ],
    //     purpose: [
    //       "Cosmetic Improvement: Enhances skin appearance by removing unsightly or bothersome growths.",
    //       "Medical Concerns: Eliminates potential irritations or discomfort caused by moles, skin tags, or warts.",
    //       "Preventive Measures: Reduces the risk of irritation, infection, or changes in appearance.",
    //       "Peace of Mind: Provides reassurance and peace of mind regarding benign growths.",
    //     ],
    //   },

    //   {
    //     name: "Hair PRP",
    //     img: HairPRP,
    //     head: {
    //       heading: "what is Platelet-Rich Plasma (PRP) Treatment for Hair ?",
    //       des: [
    //         "Platelet-Rich Plasma (PRP) treatment for hair is a non-surgical procedure that utilizes the patient's own blood plasma.",
    //         "PRP contains growth factors that promote hair growth and improve hair density.",
    //         "The procedure involves extracting PRP from the patient's blood and injecting it into the scalp.",
    //       ],
    //     },
    //     procedure: [
    //       "Blood Draw: A small amount of blood is drawn from the patient, usually from the arm.",
    //       "Centrifugation: The blood is processed in a centrifuge to separate the plasma rich in platelets from other blood components.",
    //       "Injection: The PRP, enriched with growth factors, is injected into the scalp at the level of the hair follicles.",
    //       "Stimulation: Growth factors in PRP stimulate hair follicles, promote hair growth, and prolong the growth phase of hair.",
    //       "Post-Treatment Care: Recommendations for scalp care and follow-up treatments to maintain results.",
    //     ],
    //     purpose: [
    //       "Hair Regrowth: Stimulates dormant hair follicles to promote new hair growth.",
    //       "Hair Thickening: Increases hair thickness and density for fuller-looking hair.",
    //       "Natural Approach: Utilizes the body's own healing factors to enhance hair growth.",
    //       "Minimal Downtime: Non-invasive procedure with minimal recovery time.",
    //     ],
    //   },
    // ],
  },
];
