import sthita from "../../images/new/DSC07973 copy (1).png";
import Satyajit from "../../images/new/Picsart_24-06-16_23-08-52-110.jpg";

export const Doctors = [
  {
    name: "Dr Sthita Gurrala",
    degree: "MDS, AOCMF Fellow (Freiburg, Germany)",
    profesional: "Oral & Maxillofacial Surgeon",
    subTitle:
      "Dr Gurrala received her MDS in Oral & Maxillofacial Surgery from Goa Dental College & Hospital.",
    img: sthita,
    des: [
      "Dr. Gurrala completed three years of surgical residency training in Oral and Maxillofacial Surgery at Goa Dental College & Hospital, Bambolim, India. During residency, Dr Gurrala received extensive training in wisdom tooth extractions, complex maxillofacial trauma and reconstruction, placement of dental implants, oral cancer, temporomandibular joint (TMJ) surgery, pre-prosthetic surgery including bone grafting, and Botox treatment.",
      "After receiving her Master of Dental Surgery degree, Dr Gurrala worked as a consultant at the Community Health Centre in Sankhalim-Goa under the Directorate of Health Services. ",
      "Dr. Gurrala has an impressive three years of experience as a Senior Resident in the Department of Oral & Maxillofacial Surgery at Goa Dental College & Hospital in India.The department collaborates closely with the Goa Medical College & Hospital, providing Dr. Gurrala with the opportunity to perform a diverse range of maxillofacial surgeries and participate in interdisciplinary surgeries alongside specialists from Neurosurgery, ENT, Ophthalmology, Plastic Surgery, and General Surgery.",
      "Dr. Gurrala is an AOCMF Fellow who received training in the Department of Oral & Maxillofacial Surgery & Regional Plastic Surgery at the University Clinic of Freiburg, Germany. Her training provided extensive exposure to a wide range of dental and maxillofacial surgical procedures.",
    ],
  },
  {
    name: "Dr Satyajit",
    degree: "BDS",
    profesional: "Dentist",
    subProfesional: "Associate",
    img: Satyajit,
    subTitle:
      "Dr. Satyajit holds a Bachelor of Dental Surgery degree from Goa Dental College & Hospital, Goa University.",
    des: [
      "With 8 years of experience in operating his dental practice, Dr. Satyajit is eager to share his expertise and knowledge at Face Multispecialty Clinic, showcasing exceptional skills in the field of dentistry.",
      "He is proficient in aesthetic rehabilitation of endodontically treated teeth and aesthetic restoration. He demonstrates astute clinical skills in re-treating cases and excels in overcoming errors.",
      "During his leisure time, Dr. Satyajit is an enthusiastic trekker, often embarking on challenging expeditions to scale the heights of the Western Ghats or the Himalayas.",
    ],
  },
];
